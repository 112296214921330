<template>
  <div
    class="module-renderer"
    :class="{
      'landscape-full-width': landscapeFullWidth,
      'no-animation': this.module.with_animation === false,
    }"
  >
    <transition name="rendered-module-fade" mode="out-in">
      <div
        class="module-renderer--content"
        :key="`${generateKey(renderedMainModule)}`"
      >
        <component
          :is="getComponent(renderedMainModule)"
          :module="renderedMainModule"
          @setFullWidth="setLandscapeFullWidth($event)"
        />

        <transition name="scroll" mode="out-in">
          <PageComponent
            v-if="currentModuleIsPage"
            :module="module"
            class="fullsize"
          />
        </transition>
        <transition name="scroll" mode="out-in">
          <RewardPageComponent
            v-if="currentModuleIsRewardPage"
            :module="module"
            class="fullsize"
          />
        </transition>
        <transition name="scroll" mode="out-in">
          <ProductComponent v-if="currentModuleIsProduct" :module="module" class="fullsize" />
        </transition>
        <transition name="scroll" mode="out-in">
          <Form v-if="currentModuleIsForm" :module="module" class="fullsize" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  PlayerModule,
  PlayerModuleType,
  Page,
  RewardPage,
} from 'smucio-stream-entities';
import Cheer from '@/components/Cheer.vue';
import Folder from '@/components/Folder.vue';
import PageComponent from '@/components/Page.vue';
import RewardPageComponent from '@/components/RewardPage.vue';
import ProductComponent from '@/components/Product.vue';
import Poll from '@/components/Poll.vue';
import Form from '@/components/Form.vue';
import LightCheer from '@/components/LightCheer.vue';

export default {
  name: 'ModuleRenderer',
  props: {
    module: {
      type: Object,
      required: true,
      validator: (value) => {
        return (
          value instanceof PlayerModule ||
          value instanceof Page ||
          value instanceof RewardPage
        );
      },
    },
    enableMultipleModules: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    Form,
    PageComponent,
    ProductComponent,
    RewardPageComponent,
  },
  data() {
    return {
      landscapeFullWidth: false,
    };
  },
  computed: {
    renderedMainModule() {
      return this.renderMultipleModules ? this.previousModule : this.module;
    },
    previousModule() {
      return this.$store.getters['navigation/previousModule'];
    },
    currentModuleIsForm() {
      return this.module.type === PlayerModuleType.Form;
    },
    currentModuleIsPage() {
      return (
        this.module.type === PlayerModuleType.Page ||
        this.module instanceof Page
      );
    },
    currentModuleIsRewardPage() {
      return this.module instanceof RewardPage;
    },
    currentModuleIsProduct() {
      return this.module.type === PlayerModuleType.Product;
    },
    renderMultipleModules() {
      if (!this.enableMultipleModules) {
        return false;
      }
      return (
        this.currentModuleIsForm ||
        this.currentModuleIsPage ||
        this.currentModuleIsRewardPage ||
        this.currentModuleIsProduct
      );
    },
  },
  methods: {
    getComponent(module) {
      switch (module.type) {
        case PlayerModuleType.Cheer:
          return Cheer;
        case PlayerModuleType.Folder:
          return Folder;
        case PlayerModuleType.Poll:
          return Poll;
        case PlayerModuleType.LightCheer:
          return LightCheer;
        // case PlayerModuleType.Form:
        // case PlayerModuleType.Page:
        // case PlayerModuleType.Product:
        default:
          return null;
      }
    },
    generateKey() {
      const object = this.renderedMainModule.toObject();
      delete object.children;

      return JSON.stringify(object);
    },
    setLandscapeFullWidth(data) {
      this.landscapeFullWidth = data;
    },
  },
};
</script>
