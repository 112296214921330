import {
  Player,
  PlayerModule,
  PlayerModuleSkin,
  PlayerModuleType,
  CloudflareVideo,
  Media,
  PlayerAppearanceSettings,
  PlayerVideoSettings,
  PlayerCardProduct,
} from 'smucio-stream-entities';

import { ICON_SAMSUNG, ICON_SKATEBOARD } from '@/examples/consts';

const player = new Player({
  id: '750d32c5-b8ec-43f7-90a0-4f9abd786513',
  key: '4e99e9b344af3684504a3c7750aa283c',
  title: 'oqssports.presentation',
  appearance_settings: new PlayerAppearanceSettings({
    button_color: '#38138E',
    show_share_button: true,
    show_viewers: true,
  }),
  video_play_settings: new PlayerVideoSettings({
    autoplay: false,
    muted: true,
    loop: true,
  }),
  cloudflare_video: new CloudflareVideo({
    url: 'https://customer-ylfen2s4ap73yzru.cloudflarestream.com/adc9134bab359fc52de8efb192c1140e/manifest/video.m3u8',
  }),
  module: new PlayerModule({
    type: PlayerModuleType.Folder,
    title: 'Ez a fő folder',
    description: 'Legfelső modul, 2x2',
    icon: ICON_SKATEBOARD,
    sponsor_logo: ICON_SAMSUNG,
    background:
      'radial-gradient(159.23% 122.86% at 50.29% -6.57%, #5A33F4 0%, #10C285 100%)',
    background_image: new Media({
      url: 'https://cdn2.thecatapi.com/images/181.jpg',
    }),
    children: [
      new PlayerModule({
        type: PlayerModuleType.Product,
        skin: PlayerModuleSkin.ListItem,
        background: 'radial-gradient(159.23% 122.86% at 50.29% -6.57%, #5A33F4 0%, #10C285 100%)',
        product: new PlayerCardProduct({
          // id: 'bcaba95e-acc9-498c-8ea5-bf8b299acd1d',
          id: 'f374dc53-583f-4775-84e9-ef6bdc0c37f6',

          /* sale_price: 100,
          price: 100,
          name: 'kecske',
          image: new Media({ url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcudcK_sBKxRNk00XALZ-GyDVvFol1iO0e6Q&s' }),
          description: `
            Tongue ribeye short ribs meatball, pork chop pork ball tip ham hock boudin porchetta frankfurter chuck drumstick pastrami. Pig pancetta pork loin, drumstick jowl swine beef ribs tri-tip hamburger bacon cow ham alcatra filet mignon. Ham hock short ribs tongue boudin chicken turducken. Alcatra chicken leberkas, tongue filet mignon andouille shoulder short loin rump jowl ground round doner brisket meatball boudin.

            Beef ribs fatback ham hock, tail sausage short ribs kielbasa shank andouille. Spare ribs kielbasa shank cupim ham, filet mignon porchetta swine short ribs jowl chuck buffalo. Spare ribs tenderloin meatloaf chuck chicken. Jowl shoulder jerky strip steak filet mignon shankle pork chop salami hamburger corned beef shank.

            Does your lorem ipsum text long for something a little meatier? Give our generator a try… it's tasty!
          `, */
        }),
      }),
    ],
  }),
});

export default player.toObject(false);
