const storageEnabled = window.localStorage !== undefined;

let answers = '{}';
if (storageEnabled) {
  answers = window.localStorage.getItem('pollAnswers') ?? '{}';
}
answers = JSON.parse(answers);

const state = {
  answers,
  results: {},
};

const getters = {
};

const actions = {
};

const mutations = {
  setAnswer(state, { id, answerId }) {
    state.answers[id] = answerId;
    if (storageEnabled) {
      window.localStorage.setItem('pollAnswers', JSON.stringify(state.answers));
    }
  },
  setResults(state, { id, results }) {
    state.results[id] = results;
  },
  increase(state, { id, optionId, value }) {
    if (!(id in state.results)) {
      state.results[id] = {};
    }

    state.results[id][optionId] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
