<template>
  <div class="share-contanier">
    <button class="header-button" @click="openModal()">
      <i class="icon share white" />
      {{ tr('Share') }}
    </button>
    <ShareStreamModal
      v-if="showModal"
      :title="tr('Share this stream')"
      :url="url"
      :buttonColor="'#000000'"
      :textColor="'#ffffff'"
      @close="closeModal()"
    />
  </div>
</template>

<script>
import ShareStreamModal from '@/components/ShareStreamModal.vue';

export default {
  name: 'ShareButton',
  components: {
    ShareStreamModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    url() {
      return window.location.href;
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
