import {
  PlayerModuleType,
} from 'smucio-stream-entities';

export default {
  methods: {
    creditReward() {
      if (
        this.module.reward === null
        || typeof this.module.reward === 'undefined'
        || this.module.type === PlayerModuleType.Folder
      ) {
        return;
      }

      this.$store.dispatch('reward/creditPoints', { module: this.module });
    }
  }
}
