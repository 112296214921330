<template>
  <div class="poll">
    <ModuleHeader
      :title="module.title"
      :description="module.description"
      :icon="module.icon"
      :sponsorLogo="module.sponsor_logo"
      divider
    />
    <div class="poll--content">
      <div class="options" v-if="!voteNumberVisible">
        <div
          v-for="(option, index) in poll.answers"
          :key="'poll-' + poll.id + '--option-' + option.id + '--' + index"
          :class="optionClasses(option, index)"
          :style="{ '--bgColor': option.color || '#000' }"
          @click="() => selectOption(option)"
        >
          <div class="text">
            {{ option.text }}
          </div>
        </div>
      </div>

      <div class="results" v-else>
        <div
          v-for="(option, index) in poll.answers"
          :key="'poll-' + poll.id + '--option-' + option.id + '--' + index"
          class="result"
          :style="resultStyles(option)"
        >
          <div class="bar gloss-effect gloss-effect--small" />
          <div class="textual">
            <div class="votes">
              {{ counterLabel(getRenderedCount(option)) }}
            </div>
            <div class="text">
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="poll.closed" class="closed-text">
      {{ tr('This poll is closed.') }}
    </div>
  </div>
</template>

<script>
import RewardMixin from '@/mixins/Reward.js';

import { PlayerModule } from 'smucio-stream-entities';
import ModuleHeader from '@/components//ModuleHeader.vue';
import { WsClientEvents } from 'smucio-ws-client';

import { counterLabel } from '@/utils/formatters.js';

export default {
  name: 'PollComponent',
  mixins: [RewardMixin],
  components: {
    ModuleHeader,
  },
  props: {
    module: {
      type: PlayerModule,
      required: true,
    },
  },
  data: () => ({
    loadingOption: null,
    renderedResults: {},
    renderedResultAnimationCompleted: false,
  }),
  computed: {
    poll() {
      return this.module.poll;
    },
    voteNumberVisible() {
      return this.isAnswered || this.poll.closed;
    },
    totalAnswerCount() {
      const values = Object.values(this.results);
      return values.reduce((a, b) => a + b, 0);
    },
    resultStyles() {
      return (option) => {
        const maxVotes = Math.max(...Object.values(this.results));
        const percentage = (this.getCount(option) / maxVotes) * 100;
        return {
          '--bgColor': option.color || '#000',
          '--percentage': percentage + '%',
        };
      };
    },
    results() {
      if (!(this.poll.id in this.allResults)) {
        return {};
      }

      return this.allResults[this.poll.id];
    },
    allResults() {
      return this.$store.state.poll.results;
    },
    userAnswer() {
      const pollAnswers = this.$store.state.poll.answers;
      if (!(this.poll.id in pollAnswers)) {
        return null;
      }

      return pollAnswers[this.poll.id];
    },
    isAnswered() {
      return this.userAnswer !== null;
    },
  },
  watch: {
    voteNumberVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.poll.answers.forEach((option) => {
            this.tweenVotes(option);
          });
        }
      },
    },
    results(val) {
      if (this.renderedResultAnimationCompleted) {
        this.renderedResults = val;
      }
    },
  },
  methods: {
    counterLabel,
    optionClasses(option, index) {
      const classes = {
        'option': true,
        'active': this.isActiveOption(option),
        'loading': option.id === this.loadingOption,
        'selectable': !this.isAnswered,
        'gloss-effect': true,
        'gloss-effect--small': true,
        'rounded': true,
      };

      const radiusType = index % 4 === 0 || index % 4 === 3 ? 'tl' : 'tr';
      classes['rounded--' + radiusType] = radiusType;

      return classes;
    },
    isActiveOption(option) {
      return option.id === this.userAnswer;
    },
    getCount(option) {
      return option.id in this.results ? this.results[option.id] : 0;
    },
    getRenderedCount(option) {
      return option.id in this.renderedResults ? this.renderedResults[option.id] : 0;
    },
    selectOption(option) {
      if (
        this.loadingOption !== null ||
        this.isAnswered ||
        this.poll.closed
      ) {
        return;
      }

      this.loadingOption = option.id;

      this.$root.wsClient.addEventListener(
        WsClientEvents.POLL_VOTE_FEEDBACK,
        this.onVoteFeedback
      );
      this.$nextTick(() =>
        this.$root.wsClient.pollVote(this.poll.id, option.id)
      );
    },
    onVoteFeedback(message) {
      if (message.id !== this.poll.id) {
        return;
      }

      this.creditReward();

      this.$root.wsClient.removeEventListener(
        WsClientEvents.POLL_VOTE_FEEDBACK,
        this.onVoteFeedback
      );

      this.$store.commit('poll/setAnswer', { id: this.poll.id, answerId: this.loadingOption });
      this.$store.commit('poll/setResults', { id: message.id, results: message.results });

      this.loadingOption = null;
    },
    tweenVotes(option) {
      const duration = 1000;
      const end = this.getCount(option);
      this.renderedResults[option.id] = end !== 0 ? 1 : 0;

      const interval = setInterval(() => {
        if (this.renderedResults[option.id] < end) {
          this.renderedResults[option.id] += 1;
        }
        else {
          clearInterval(interval);
        }
      }, duration / end);

      setTimeout(() => {
        this.$nextTick(() => {
          this.renderedResults = this.results;
          this.renderedResultAnimationCompleted = true;
        });
      }, duration)
    },
  }
};
</script>
