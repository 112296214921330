<template>
  <div class="folder" :class="{ bounce: isBouncing }">
    <ModuleHeader
      :title="title"
      :description="description"
      :icon="icon"
      :sponsorLogo="module.sponsor_logo"
      :divider="true"
    />

    <div
      class="folder--modules"
      ref="modules"
      @scroll="stopBounce"
      @touchstart="stopBounce"
      @click="stopBounce"
    >
      <component
        v-for="(child, index) in children"
        :is="getComponentType(child)"
        :key="index"
        :class="moduleClasses(child, index)"
        :playerModule="child"
      />
    </div>
  </div>
</template>

<script>
import {
  PlayerModule,
  PlayerModuleSkin,
  PlayerModuleType,
} from 'smucio-stream-entities';
import Module from '@/components/Module.vue';
import ModuleHeader from '@/components/ModuleHeader.vue';
import Ad from '@/components/Ad.vue';

export default {
  name: 'FolderComponent',
  components: {
    Module,
    ModuleHeader,
    Ad,
  },
  props: {
    module: {
      type: PlayerModule,
      required: true,
    },
  },
  data() {
    return {
      isBouncing: false,
      bounceInterval: null,
    };
  },
  computed: {
    title() {
      return this.module.title;
    },
    description() {
      return this.module.description;
    },
    icon() {
      return this.module.icon;
    },
    children() {
      return this.module.children;
    },
    isModulesScrollable() {
      if (!this.$refs.modules) {
        return false;
      }
      return this.$refs.modules.scrollHeight > this.$refs.modules.clientHeight;
    },
  },
  methods: {
    getComponentType(playerModule) {
      return playerModule.ad ? Ad : Module;
    },
    moduleClasses(module, index) {
      const classes = {};

      if (
        index !== 0 &&
        module.skin !== PlayerModuleSkin.ListItem &&
        this.children[index - 1].skin === PlayerModuleSkin.ListItem
      ) {
        classes['margin-top'] = true;
      }

      if (module.type === PlayerModuleType.Ad) {
        if (!module.ad.rounded) {
          return classes;
        }
      }

      if (module.skin === PlayerModuleSkin.ListItem) {
        return classes;
      }

      let type = 'tl';
      let renderedCells = 0;

      for (let i = 0; i < index; ++i) {
        if (this.children[i].skin === PlayerModuleSkin.Box2x2) {
          ++renderedCells;
        } else {
          renderedCells += 2;
        }

        if (
          this.children[i].skin === PlayerModuleSkin.Box2x2 &&
          renderedCells % 2 === 1
        ) {
          type = type === 'tl' ? 'tr' : 'tl';
        }
      }

      classes['rounded'] = true;
      classes['rounded--' + type] = true;

      return classes;
    },
    startBounce() {
      this.stopBounce();
      this.bounceInterval = setInterval(() => {
        this.isBouncing = true;
        setTimeout(() => {
          this.isBouncing = false;
        }, 1000);
      }, 5000);
    },
    stopBounce() {
      if (this.bounceInterval) {
        clearInterval(this.bounceInterval);
        this.bounceInterval = null;
      }
      this.isBouncing = false;
    },
  },

  mounted() {
    // will be useful when folder herozontal scroll is needed 
    // if (false) {
    //   this.$emit('setFullWidth', true);
    // }
    // have to wait for the modules to be rendered
    setTimeout(() => {
      if (
        this.isModulesScrollable &&
        !this.$store.getters['navigation/navigatedAway']
      ) {
        this.startBounce();
      }
    }, 500);
  },
};
</script>
