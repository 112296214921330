<template>
  <div v-if="shouldDisplayRewardBar" class="reward-bar-component">
    <div
      v-if="rewardPhase === 'collecting'"
      :class="{
        'reward-bar-component--progress': true,
        'fade-out-up': isTotaled,
      }"
    >
      <i class="icon fan-reward-stars white" />

      <div class="bar" :style="barStyle">
        <div class="bar--fill" />

        <div
          v-if="aboutPage !== null"
          class="bar--text"
          @click="navigateToAboutPage"
        >
          <i class="icon information white" />
          <span>{{ tr('More about the fan reward') }}</span>
        </div>
      </div>

      <div class="points">
        <div class="current">{{ currentPoints }}</div>
        /
        <div class="max">{{ maxPoints }}</div>
      </div>
    </div>
    <div
      v-else-if="rewardPhase === 'collected'"
      class="reward-bar-component-body reward-bar-component--completed"
      @click="navigateToRedeemPage"
    >
      <text-animation-wave
        v-if="!textAnimationWaveCompleted"
        :text="tr('Congratulations! Redeem your reward now! 🎉')"
      />
      <button-glossy
        v-else
        :label="tr('Get your reward now!')"
        background-color="#EA0F06"
        icon-classes="icon fan-reward-stars white"
      />
    </div>
    <div
      v-else-if="rewardPhase === 'redeemed'"
      class="reward-bar-component-body reward-bar-component--redeemed"
      @click="navigateToRewardPage"
    >
      <button-glossy
        :label="tr('View your reward now!')"
        background-color="#EA0F06"
        icon-classes="icon fan-reward-stars white"
      />
    </div>
    <div
      v-else-if="rewardPhase === 'claimed'"
      class="reward-bar-component-body reward-bar-component--claimed"
      @click="navigateToRewardPage"
    >
      <button-glossy
        :label="tr('View your reward now!')"
        background-color="#EA0F06"
        icon-classes="icon fan-reward-stars white"
      />
    </div>
  </div>
</template>

<script>
import { Page, RewardPage } from 'smucio-stream-entities';
import TextAnimationWave from '@/components/TextAnimationWave.vue';
import ButtonGlossy from '@/components/ButtonGlossy.vue'

import { isPlainObject } from '@/utils';

export default {
  name: 'RewardBar',
  props: {
    redeemPage: {
      type: RewardPage,
      required: true,
    },
    rewardPage: {
      type: RewardPage,
      required: true,
    },
    aboutPage: {
      type: Page,
      required: false,
      default: null,
    },
  },
  components: {
    ButtonGlossy,
    TextAnimationWave,
  },
  data() {
    return {
      rewardPhase: 'collecting',
      rewardPhases: [
        'collecting',
        'collected',
        'redeemed',
        'claimed',
      ],
      textAnimationWaveCompleted: false,
    };
  },
  beforeMount() {
    this.updateRewardPhase();
  },
  computed: {
    maxPoints() {
      return this.$store.getters['reward/max'];
    },
    currentPoints() {
      return this.$store.getters['reward/points'];
    },
    isTotaled() {
      return this.maxPoints == this.currentPoints;
    },
    isRedeemed() {
      return this.$store.getters['reward/isRedeemed'];
    },
    isClaimed() {
      return this.$store.getters['reward/isClaimed'];
    },
    barStyle() {
      return {
        '--reward-percentage': (this.currentPoints / this.maxPoints) * 100 + '%',
      };
    },
    shouldDisplayRewardBar() {
      return !(
        isPlainObject(this.$store.getters['navigation/currentModule']) &&
        this.$store.getters['navigation/currentModule'].constructor.name === 'RewardPage'
      );
    },
  },
  methods: {
    navigateToAboutPage() {
      this.$store.dispatch('navigation/setOpenModuleRenderer', true);
      this.$store.dispatch('navigation/setCurrentModule', this.aboutPage);
    },
    navigateToRedeemPage() {
      this.$store.dispatch('navigation/setOpenModuleRenderer', true);
      this.$store.dispatch('navigation/setCurrentModule', this.redeemPage);
    },
    navigateToRewardPage() {
      this.$store.dispatch('navigation/setOpenModuleRenderer', true);
      this.$store.dispatch('navigation/setCurrentModule', this.rewardPage);
    },
    updateRewardPhase() {
      if (this.isClaimed) {
        this.rewardPhase = 'claimed';
      } else if (this.isRedeemed) {
        this.rewardPhase = 'redeemed';
      } else if (this.isTotaled) {
        this.rewardPhase = 'collected';
      } else {
        this.rewardPhase = 'collecting';
      }

      if (this.rewardPhase == 'collected') {
        setTimeout(() => this.textAnimationWaveCompleted = true, 5000)
      }
    },
  },
  watch: {
    isTotaled(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        setTimeout(() => {
          this.updateRewardPhase()
        }, 750);
      }
    },
    isRedeemed() {
      this.updateRewardPhase();
    },
    isClaimed() {
      this.updateRewardPhase();
    },
  },
};
</script>
