<template>
  <div class="share--modal" @click="eventOnShareModal">
    <div ref="shareModalInner" class="inner">
      <span class="close-button" @click="eventOnShareModal">
        <i class="icon close black" ref="closeButton" />
      </span>
      <div class="title">{{ theTitle }}</div>

      <div @click="shareLinkCopy" class="share--input">
        <input v-model="urlCopy" readonly />
        <span>
          <i class="icon chain light-gray" />
        </span>
      </div>
      <button
        :style="'background-color:' + buttonColor + '; color: ' + textColor"
        @click="shareLinkCopy"
      >
        {{ shareLinkText }}
      </button>
    </div>
  </div>
</template>

<script>
import TranslatorMixin from '@/mixins/Translator.js';

export default {
  name: 'ShareThisStreamModal',
  mixins: [TranslatorMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      required: false,
      default() {
        return '#000000';
      },
    },
    textColor: {
      type: String,
      required: false,
      default() {
        return '#ffffff';
      },
    },
  },
  data() {
    return {
      shareLinkCopied: false,
      isNavigatorShareSupported: false,
      urlCopy: '',
    };
  },
  computed: {
    theTitle() {
      return this.isNavigatorShareSupported
        ? this.tr('Share with others')
        : this.tr('Copy link to clipboard');
    },
    shareLinkText() {
      if (this.isNavigatorShareSupported) {
        return this.tr('Share');
      }

      return this.shareLinkCopied
        ? this.tr('Copied to clipboard')
        : this.tr('Copy');
    },
  },
  created() {
    this.urlCopy = this.url;
    this.isNavigatorShareSupported = typeof navigator.share == 'function';
  },
  methods: {
    eventOnShareModal(e) {
      const path = e.composedPath ? e.composedPath() : [e.target];
      const inner = this.$refs.shareModalInner;
      const btn = this.$refs.closeButton;
      if (!path.includes(inner) || path.includes(btn)) {
        this.$emit('close');
      }
    },
    async shareLinkCopy(e) {
      e.preventDefault();
      e.stopPropagation();

      // Native share on mobile devices
      if (this.isNavigatorShareSupported) {
        try {
          await navigator.share({
            title: this.title,
            text: this.title,
            url: this.url,
          });
        } catch (err) {
          console.error(err);
        }

        return;
      }

      // Default share, copy to clipboard..
      if (!navigator.clipboard) {
        return;
      }

      try {
        navigator.clipboard.writeText(this.url);

        this.shareLinkCopied = true;
        setTimeout(() => {
          this.shareLinkCopied = false;
        }, 1500);
      } catch (err) {
        console.error('Failed to copy!', err);
      }
    },
  },
};
</script>
