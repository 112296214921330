export function counterLabel(value, precision) {
  if (value < 1000) {
    return value;
  }

  let resp = parseFloat(value / 1000);
  if (precision) {
    resp.toFixed(precision);
  }
  else if (value < 100000) {
    resp = resp.toFixed(1);
  }

  return resp + 'K';
}

export function formatPrice(price, currency) {
  let formattedValue = price.toFixed(currency.toUpperCase() === 'HUF' ? 0 : 2);
  switch (currency.toUpperCase()) {
    case 'USD':
      formattedValue = '$' + formattedValue;
      break;

    case 'EUR':
      formattedValue = '€' + formattedValue;
      break;

    case 'HUF':
      formattedValue = formattedValue + ' Ft';
      break;

    default:
      formattedValue += ' ' + currency.toUpperCase();
  }

  return formattedValue;
}
