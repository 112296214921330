<template>
  <a :class="wrapperClasses" :href="ad.link" target="_blank">
    <img :src="ad.media.url" class="cover-image" alt="ad" />
  </a>
</template>

<script>
import { PlayerModule } from 'smucio-stream-entities';

export default {
  name: 'AdComponent',
  props: {
    playerModule: {
      type: PlayerModule,
      required: true,
    },
  },
  computed: {
    ad() {
      return this.playerModule ? this.playerModule.ad : null;
    },
    wrapperClasses() {
      let classes = { 'ad-component': true };
      if (this.ad.rounded) {
        classes['rounded'] = true;
        classes['rounded--tl'] = true;
      }

      return classes;
    },
  },
};
</script>
