const state = {
  results: {},
};

const getters = {
};

const actions = {
};

const mutations = {
  setResults(state, { id, results }) {
    state.results[id] = results;
  },
  increase(state, { id, optionId, value }) {
    if (!(id in state.results)) {
      state.results[id] = {};
    }

    state.results[id][optionId] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
