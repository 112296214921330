<template>
  <div v-if="showHeader" class="module-header" :class="{ divider: divider }">
    <div class="module-header--main">
      <img v-if="icon" class="icon" :src="icon" alt="folder-icon" />
      <div class="textual">
        <div v-if="title" class="title" v-html="title" />
        <div v-if="description" class="description" v-html="description" />
      </div>
    </div>
    <img v-if="computedSponsorLogo" class="sponsor-logo" :src="computedSponsorLogo" alt="sponsor-logo" />
  </div>
</template>

<script>
export default {
  name: 'ModuleHeader',
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    icon: {
      // Base64 image
      type: String,
      required: false,
    },
    sponsorLogo: {
      type: String,
      required: false,
    },
    divider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    showHeader() {
      return this.title || this.description || this.icon;
    },
    computedSponsorLogo() {
      if (this.sponsorLogo === '') {
        return false;
      }
      return this.sponsorLogo ? this.sponsorLogo : this.$root.defaultSponsorLogo;
    },
  },
};
</script>
