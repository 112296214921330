const state = {
  path: [],
  isInPseudoModule: false,
  previousModule: null,
  navigatedAway: false,
  openModuleRenderer: false,
};

const getters = {
  currentModule(state) {
    return state.path.length !== 0 ? state.path[state.path.length - 1] : null;
  },
  previousModule(state) {
    return state.previousModule;
  },
  path(state) {
    return state.path;
  },
  rootModule(state) {
    return state.path[0];
  },
  isInRoot(state) {
    return state.path.length === 1;
  },
  isInPseudoModule(state) {
    return state.isInPseudoModule;
  },
  navigatedAway(state) {
    return state.navigatedAway;
  },
  openModuleRenderer(state) {
    return state.openModuleRenderer;
  },
};

const mutations = {
  setCurrentModule: (state, module) => {
    // Prevent the browser from going back to the previous page
    window.history.pushState({}, '', '');
    state.previousModule = state.path[state.path.length - 1];
    state.path.push(module);

    if (state.path.length > 1) {
      state.navigatedAway = true;
    }
  },
  goBack: (state) => {
    if (state.isInPseudoModule) {
      state.isInPseudoModule = false;
      return;
    }

    if (state.path.length === 1) {
      return;
    }

    state.previousModule = state.path[state.path.length - 1];

    state.path.pop();
  },
  setPseudoModule: (state) => {
    state.isInPseudoModule = true;
  },
  setOpenModuleRenderer: (state, value) => {
    state.openModuleRenderer = value;
  },
  setPath: (state, path) => {
    state.path = path;
  },
};

const actions = {
  setCurrentModule({ commit }, module) {
    commit('setCurrentModule', module);
  },
  goBack({ commit }) {
    commit('goBack');
  },
  goToPseudoModule({ commit }) {
    commit('setPseudoModule');
  },
  setOpenModuleRenderer({ commit }, value) {
    commit('setOpenModuleRenderer', value);
  },
  setPath({ commit }, path) {
    commit('setPath', path);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
