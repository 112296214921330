<template>
  <div class="viewer-count">
    <i class="icon viewer white" />

    <i v-if="loading" class="icon loader white" />
    <template v-else>
      {{ customViewerCount }}
    </template>
  </div>
</template>

<script>
import { counterLabel } from '@/utils/formatters.js';

export default {
  name: 'ViewerCount',
  props: {
    viewerCount: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    customViewerCount() {
      return counterLabel(this.viewerCount, 1);
    },
  },
};
</script>
