<template>
  <div class="page">
    <ModuleHeader
      :title="module.title"
      :description="module.description"
      :icon="module.icon"
      :sponsorLogo="module.sponsor_logo"
    />

    <div class="page--content">
      <hls-player v-if="hlsVideo" :src="hlsVideo.url" rounded />

      <div v-if="showContentLead" class="lead" v-html="contentLead" />
      <div class="content" v-html="computeContent" />
    </div>
  </div>
</template>

<script>
import RewardMixin from '@/mixins/Reward.js';
import { Media, PlayerModule, Page } from 'smucio-stream-entities';
import ModuleHeader from '@/components/ModuleHeader.vue';
import HlsPlayer from '@/components/HlsPlayer.vue';
import Ad from '@/components/Ad.vue';

import { render, h } from 'vue';

export default {
  name: 'PageComponent',
  mixins: [RewardMixin],
  components: {
    ModuleHeader,
    HlsPlayer,
  },
  props: {
    module: {
      type: Object,
      required: true,
      validator: (value) => {
        return value instanceof PlayerModule || value instanceof Page;
      },
    },
  },
  computed: {
    page() {
      return this.module.page ? this.module.page : this.module;
    },
    hlsVideo() {
      return this.page.cloudflare_video;
    },
    showHeader() {
      return this.module.title || this.module.description || this.module.icon;
    },
    computeContent() {
      // Take out shorttag for Ad [ad src="" link="" rounded=""]
      const adShortTag =
        /\[ad\s+img="([^"]+)"\s+link="([^"]+)"(\s+rounded="(true|false)")?\]/g;

      // Replace shorttag with Ad component
      let replacedContent = this.page.content.replace(
        adShortTag,
        (match, src, link, _roundedWrapper, rounded) => {
          const playerModule = new PlayerModule({
            ad: {
              media: new Media({ url: src }),
              link,
              rounded: rounded === 'true',
            },
          });
          const adComponent = h(Ad, { class: 'in-page', playerModule });
          const adElement = document.createElement('div');
          render(adComponent, adElement);
          return adElement.innerHTML;
        }
      );

      return replacedContent;
    },
    showContentLead() {
      if (!this.module.page) {
        return this.page.lead;
      } else {
        return this.module.page.lead;
      }
    },
    contentLead() {
      if (!this.module.page) {
        return this.page.lead;
      } else {
        return this.module.page.lead;
      }
    },
  },
  mounted() {
    this.creditReward();
  }
};
</script>
