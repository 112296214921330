<template>
  <div class="cheer">
    <ModuleHeader
      :title="module.title"
      :description="module.description"
      :icon="module.icon"
      :sponsorLogo="module.sponsor_logo"
      divider
    />
    <div class="teams">
      <div
        v-for="(team, index) in cheer.teams"
        :key="'cheer-' + team.id + '--team-' + team.id + '--' + index"
        class="team-wrapper"
        @click="() => onClick(team)"
      >
        <div
          :class="teamClasses(index)"
          :style="{ '--bgColor': team.color || '#999' }"
        >
          <div class="text">
            {{ team.name }}
          </div>

          <div v-if="team.logo && team.logo.url" class="logo-wrapper">
            <img :src="team.logo.url" class="logo" />
          </div>

          <div class="count">
            {{ team.id in results ? counterLabel(results[team.id]) : 0 }}
          </div>
        </div>

        <div v-if="team.id in animatedItems" class="animation-start-wrapper">
          <div
            v-for="item in animatedItems[team.id]"
            :key="'item--' + item.id"
            :class="item.classes"
          >
            <img
              v-if="team.logo && team.logo.url"
              :src="team.logo.url"
              class="logo"
            />
            <div v-else class="icon-wrapper">
              <i class="icon clap white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RewardMixin from '@/mixins/Reward.js';

import ModuleHeader from '@/components//ModuleHeader.vue';
import { PlayerModule } from 'smucio-stream-entities';
import { WsClientEvents } from 'smucio-ws-client';

import { counterLabel } from '@/utils/formatters.js';

export default {
  name: 'cheerComponent',
  components: {
    ModuleHeader,
  },
  props: {
    module: {
      type: PlayerModule,
      required: true,
    },
  },
  mixins: [RewardMixin],
  data: () => ({
    animatedItems: {},
    lastAnimation: null,
  }),
  computed: {
    cheer() {
      return this.module.cheer;
    },
    results() {
      if (!(this.cheer.id in this.allResults)) {
        return {};
      }

      return this.allResults[this.cheer.id];
    },
    allResults() {
      return this.$store.state.cheer.results;
    },
  },
  watch: {
    '$root.wsClient': {
      immediate: true,
      handler(wsClient) {
        if (!wsClient) {
          return;
        }

        wsClient.addEventListener(WsClientEvents.CHEER, this.onReceiveCheer);
      },
    },
  },
  beforeUnmount() {
    if (this.$root.wsClient) {
      this.$root.wsClient.removeEventListener(WsClientEvents.CHEER, this.onReceiveCheer);
    }
  },
  methods: {
    counterLabel,
    onReceiveCheer({ data }) {
      if (data.id === this.cheer.id) {
        this.trigger(data.team_id);
      }
    },
    onClick(team) {
      if (this.$root.wsClient) {
        this.$root.wsClient.cheer({
          cheerId: this.module.cheer.id,
          teamId: team.id,
        });
      }

      const newValue = team.id in this.results ? (this.results[team.id] + 1) : 1;
      this.$store.commit('cheer/increase', { id: this.cheer.id, optionId: team.id, value: newValue });
      this.trigger(team.id);

      this.creditReward();
    },
    trigger(teamId) {
      const now = Date.now();
      if (this.lastAnimation + 100 >= now) {
        return;
      }

      if (!(teamId in this.animatedItems)) {
        this.animatedItems[teamId] = [];
      }

      this.lastAnimation = now;
      const itemId = this.generateItemId();
      const randomX = Math.floor(Math.random() * 3 + 1);
      const randomY = Math.floor(Math.random() * 3 + 1);
      const randomXDelay = Math.floor(Math.random() * 3 + 1);
      const randomYDelay = Math.floor(Math.random() * 3 + 1);

      this.animatedItems[teamId].push({
        id: itemId,
        classes: [
          'item',
          'shake-y-' + randomY,
          'shake-x-' + randomX,
          'shake-x-delay-' + randomXDelay,
          'shake-y-delay-' + randomYDelay,
        ]
      });
      setTimeout(() => {
        this.animatedItems[teamId] = this.animatedItems[teamId].filter(e => e.id !== itemId);
      }, 5000);
    },
    generateItemId() {
      return (Math.random() + 1).toString(36).substring(7);
    },
    teamClasses(index) {
      const classes = {
        'team': true,
        'gloss-effect': true,
        'gloss-effect--small': true,
        'rounded': true,
      };

      const radiusType = index % 4 === 0 || index % 4 === 3 ? 'tl' : 'tr';
      classes['rounded--' + radiusType] = radiusType;

      return classes;
    },
  }
};
</script>
