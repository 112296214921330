import { createStore } from 'vuex'

import app from '@/store/modules/app';
import cheer from '@/store/modules/cheer';
import navigation from './modules/navigation';
import poll from './modules/poll';
import reward from './modules/reward';

let modules = {
  app,
  cheer,
  navigation,
  poll,
  reward,
}

export default createStore({
  modules
})
