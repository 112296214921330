import('@/assets/css/dsp.css');
import('@/assets/scss/styles.scss');

import { createApp } from 'vue'
import mitt from 'mitt';
import App from '@/App.vue'
import '@/registerServiceWorker'

const app = createApp(App);
app.config.globalProperties.emitter = mitt();

// Store
import store from '@/store'
app.use(store);

// Translation
import Translator from '@/mixins/Translator';
app.mixin(Translator);

// Toast
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

app.use(Vue3Toastify, {
  position: 'top-right',
  autoClose: 3000,
});

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
app.component('v-select', vSelect);

window.addEventListener('load', () => {
  app.mount('#app');
});
