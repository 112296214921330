<template>
  <div class="hls-player">
    <video
      ref="video"
      preload="metadata"
      :autoplay="settings.autoplay"
      :muted="settings.muted"
      :loop="settings.loop"
      playsinline
      crossorigin
    />

    <transition name="fade">
      <i v-show="isLoading" class="icon loader white" />
    </transition>

    <slot />
  </div>
</template>

<script>
import Dsp from '@/dsp.js';
import Hls from 'hls.js';

import { PlayerVideoSettings } from 'smucio-stream-entities';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const lowQualityMode = true;

export default {
  name: 'HlsPlayer',
  props: {
    src: {
      type: String,
      required: true,
    },
    settings: {
      type: PlayerVideoSettings,
      required: false,
      default: () => new PlayerVideoSettings({
        autoplay: false,
        muted: false,
        loop: true,
      }),
    },
    isLive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    likeButtonPlaceholder: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      isLoading: true,
      hls: null,
      player: null,
      dspOptions: {
        autoplay: this.settings.autoplay,
        loop: { active: this.settings.loop },
        volume: 1,
        muted: this.settings.muted,
        clickToPlay: !this.settings.autoplay,
        // i18n: { qualityLabel: { 0: 'Auto' } },
        // settings: [ 'quality' ],
        // fullscreen: !adMode ? { enabled: true, fallback: true, iosNative: true, container: null } : false,
        controls: [
          'play-large',
          'play',
          'mute',
          // 'volume',
          'fullscreen',

          // 'current-time',
        ],
        invertTime: false,
        resetOnEnd: !this.settings.loop,
        poster: null,
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.initEventListeners();
      this.hls = await this.initHls();
      this.player = this.initPlyr();
    },
    initEventListeners() {
      this.$refs.video.addEventListener('loadstart', () => this.$emit('loadstart'));
      this.$refs.video.addEventListener('loadeddata', () => this.$emit('loadeddata'));
      this.$refs.video.addEventListener('loadedmetadata', () => this.$emit('loadedmetadata'));
      this.$refs.video.addEventListener('play', () => this.$emit('play'));
      this.$refs.video.addEventListener('pause', () => this.$emit('pause'));
      this.$refs.video.addEventListener('ended', () => this.$emit('ended'));
    },
    initHls() {
      return new Promise(resolve => {
        if (!Hls.isSupported()) {
          this.$refs.video.src = this.src;
          return resolve(null);
        }

        const hls = new Hls({
          maxMaxBufferLength: lowQualityMode ? 10 : 20,
        });
        if (lowQualityMode) {
          hls.currentLevel = 1;
        }

        hls.loadSource(this.src);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const availableQualities = hls.levels.map((l) => l.height);
          availableQualities.unshift(0);

          this.dspOptions.quality = {
            options: availableQualities,
            default: 0,
            forced: true,
            onChange: (newQuality) => {
              if (newQuality === 0) {
                hls.currentLevel = -1;
              }
              else {
                hls.levels.forEach((level, levelIndex) => {
                  if (level.height === newQuality) {
                    hls.currentLevel = levelIndex;
                  }
                });
              }
            },
          };
        });

        let resolved = false;
        hls.on(Hls.Events.FRAG_BUFFERED, () => {
          if (!resolved) {
            resolved = true;
            resolve(hls);
          }
        });

        hls.attachMedia(this.$refs.video);
      });
    },
    initPlyr() {
      if (!this.isLive) {
        this.dspOptions.controls.push('progress');
        this.dspOptions.controls.push('current-time');
      }

      const player = new Dsp(this.$refs.video, this.dspOptions);
      player.volume = 1;
      player.muted = this.settings.muted;

      player.on('ready', () => {
        this.initCustomControls();

        if (this.rounded) {
          player.elements.container.classList.add('rounded');
          player.elements.container.classList.add('rounded--tl');
        }

        if (this.settings.autoplay) {
          player.muted = true;
          try {
            player.play();
          }
          catch (e) {
            console.error(e);
          }
        }

        this.$nextTick(() => this.isLoading = false);
      });

      player.on('play', () => {
        if (this.$root.$refs.mainPlayer && this.$root.$refs.mainPlayer !== this) {
          this.$root.$refs.mainPlayer.player.pause();
        }
      });

      if (isSafari) {
        player.on('play', () => {
          let nextCurrentTime = player.currentTime - 0.00001;
          if (nextCurrentTime < 0) {
            nextCurrentTime = 0;
          }
          player.currentTime = nextCurrentTime;
        });

        const check = () => {
          if (
            !(
              Object.prototype.toString.call(this.$refs.video) == '[object Object]'
              && typeof this.$refs.video.videoWidth !== 'undefined'
              && this.$refs.video.videoWidth > 0
            )
          ) {
            return setTimeout(() => check(), 50);
          }

          this.$emit('loadeddata');
        };
        check();
      }

      return player;
    },

    initCustomControls() {
      if (this.likeButtonPlaceholder) {
        const likeButtonPlaceholder = document.createElement('div');
        likeButtonPlaceholder.classList.add('dsp__controls__item', 'dsp__control', 'dsp__like-button-placeholder');
        this.player.elements.controls.appendChild(likeButtonPlaceholder);
      }
    },
  }
}
</script>
