<template>
  <button
    type="button"
    :class="cssClasses"
    :style="wrapperStyles"
  >
    <i
      v-if="iconDataUri !== null"
      class="button--glossy--icon"
      :style="{
        'background-image': 'url(' + iconDataUri + ')'
      }"
    />
    <i
      v-if="iconClasses !== null"
      :class="iconClasses"
    />
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'ButtonGlossy',
  props: {
    label: {
      type: String,
      required: true,
    },
    iconDataUri: {
      type: String,
      required: false,
      default: null,
    },
    iconClasses: {
      type: String,
      required: false,
      default: null,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#3d1094',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    glossEffect: {
      type: Boolean,
      required: false,
      default: true,
    },
    glossEffectVariant: {
      type: String,
      required: false,
      default: 'small',
      validator: (v) => ['small', 'large'].includes(v),
    },
  },
  computed: {
    cssClasses() {
      const classes = {
        'button--glossy': true,
        'has-icon': this.iconDataUri != null || this.iconClasses != null,
        'rounded--tl': true,
        'rounded--br': true,
        'disabled': this.disabled,
      };

      if (this.glossEffect) {
        classes['gloss-effect'] = true;

        classes['gloss-effect--small'] = this.glossEffectVariant === 'small';
        classes['gloss-effect--large'] = this.glossEffectVariant === 'large';
      }

      return classes
    },
    wrapperStyles() {
      let theBackground = this.backgroundColor;
      if (theBackground.match('/^(rgba?)/') || theBackground.match('^(#?[a-fA-F0-9]{6})')) {
        const lightenBackgroundColor = this.shadeColor(this.backgroundColor, 75);
        theBackground = 'linear-gradient(-128deg, ' + this.backgroundColor + ' 0%, ' + lightenBackgroundColor + ' 100%)';
      }

      if (this.disabled) {
        theBackground = 'linear-gradient(-128deg, #646464 0%, #cecece 100%)';
      }

      return {
        'background': theBackground,
      }
    }
  },
  methods: {
    shadeColor(anyColor, percent) {
      let color = anyColor;
      const matches = /^rgba?\((\d+),\s*(\d+),\s*(\d+)\)$/.exec(color)
      if (matches !== null) {
        const componentToHex = (c) => {
          const hex = c.toString(16);
          return hex.length == 1 ? "0" + hex : hex;
        }

        color = '#' + componentToHex(matches[1]) + componentToHex(matches[2]) + componentToHex(matches[3])
      }

      var R = parseInt(color.substring(1,3),16);
      var G = parseInt(color.substring(3,5),16);
      var B = parseInt(color.substring(5,7),16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;
      G = (G<255)?G:255;
      B = (B<255)?B:255;

      R = Math.round(R)
      G = Math.round(G)
      B = Math.round(B)

      var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;

      // return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + percent)).toString(16)).substr(-2));
    }
  }
}
</script>
