<template>
  <div
    class="main-view"
    :class="{
      'mobile-landscape': isMobileLandscape,
    }"
  >
    <div v-if="isDesktop" ref="clockFrame" class="clock">9:21</div>

    <transition name="fade">
      <div v-show="!isLoading" class="container">
        <template v-if="player">
          <div class="header">
            <BackButton :hidden="showBackButton" @click="onBackButtonClick" />

            <ViewerCount
              v-if="player.appearance_settings.show_viewers && !showReward"
              :viewerCount="viewers"
              :loading="waitingForWsInfo"
            />
            <ShareButton
              v-if="player.appearance_settings.show_share_button && !showReward"
            />

            <reward-bar
              v-if="showReward"
              :about-page="player.fan_about_page"
              :redeem-page="player.fan_redeem_page"
              :reward-page="player.fan_reward_page"
              @click="rewardPageOpened = true"
            />

            <div
              v-if="isMobileLandscape"
              class="fan-hub"
              @click="toggleModuleRenderer"
            >
              <span class="text">Fan hub</span>
              <div class="icon-container">
                <i class="icon fan-hub-icon white" />
              </div>
            </div>
          </div>

          <hls-player
            v-if="player.cloudflare_video"
            ref="mainPlayer"
            :src="player.cloudflare_video.url"
            :settings="player.video_play_settings"
            :is-live="mainPlayerIsLive"
            :like-button-placeholder="player.appearance_settings.show_like"
          >
            <heart-gun v-if="player.appearance_settings.show_like" />
          </hls-player>
          <ModuleRenderer
            :module="currentModule"
            :enableMultipleModules="!isMobileLandscape"
            :class="{
              opened: openModuleRenderer,
              closing: isModuleRendererClosing,
            }"
            @click="wasInRoot = false"
          />
        </template>
      </div>
    </transition>

    <transition name="fade">
      <i v-show="isLoading" class="icon loader white" />
    </transition>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import HeartGun from '@/components/HeartGun.vue';
import HlsPlayer from '@/components/HlsPlayer.vue';
import ModuleRenderer from '@/components/ModuleRenderer.vue';
import RewardBar from '@/components/RewardBar.vue';
import ShareButton from '@/components/ShareButton.vue';
import ViewerCount from '@/components/ViewerCount.vue';

import { isPlainObject } from './utils';

import {
  Player,
  PlayerLiveStatus,
  PlayerModuleType,
  PlayerType,
} from 'smucio-stream-entities';
import { WsClient, WsClientEvents } from 'smucio-ws-client';
import { mapState } from 'vuex';

import BmxPlayerDetails from '@/examples/bmx';

let playerBuilder;
let forcedPlayer;

if (process.env.NODE_ENV === 'development') {
  if (process.env.VUE_APP_PLAYER_FILE_BUILDER) {
    playerBuilder = require(process.env.VUE_APP_PLAYER_FILE_BUILDER).default;
  }

  if (process.env.VUE_APP_PLAYER_FILE) {
    forcedPlayer = require(process.env.VUE_APP_PLAYER_FILE).default;
  }
}

export default {
  name: 'App',
  components: {
    BackButton,
    HeartGun,
    HlsPlayer,
    ModuleRenderer,
    RewardBar,
    ShareButton,
    ViewerCount,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    isMobileLandscape: false,

    player: null,
    wsClient: null,
    isDataFetched: false,
    isWsConnected: false,
    waitingForWsInfo: true,
    viewers: 0,
    fontFaceMaxRetry: 10,

    isModuleRendererClosing: false,
    wasInRoot: true,
    rewardPageOpened: false,
  }),
  created() {
    let promise;

    if (forcedPlayer) {
      if (playerBuilder) {
        promise = playerBuilder(forcedPlayer).then(player => player.toObject(false));
      }
      else {
        promise = new Promise((res) => res(forcedPlayer.toObject(false)));
      }
    } else if (this.playerId) {
      promise = this.fetchData();
    } else {
      promise = new Promise((res) => res(BmxPlayerDetails));
    }

    promise.then((details) => {
      this.initPlayer(details);
      this.isDataFetched = true;
    });
  },
  mounted() {
    this.detectOrientation();
    window.addEventListener('resize', this.onResize);
    if (this.isDesktop) {
      this.updateClock();
      this.onResize();
      setInterval(() => this.updateClock(), 1000);
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  computed: {
    isDesktop() {
      return this.windowWidth > 480;
    },
    playerId() {
      const idPattern = /^([0-9a-f]{32,40})$/i;
      const pathPart = window.location.pathname.split('/')[1];

      return idPattern.test(pathPart) ? pathPart : null;
    },
    playerFilePathInQuery() {
      const params = new URLSearchParams(window.location.search);
      return params.get('file') ?? null;
    },
    isLoading() {
      if (!this.isDataFetched) {
        return true;
      }
      if (forcedPlayer || !this.playerId) {
        return false;
      }

      return !this.isWsConnected;
    },
    mainPlayerIsLive() {
      return this.player && this.player.type === PlayerType.Live;
    },
    currentModule() {
      return this.$store.getters['navigation/currentModule'];
    },
    defaultSponsorLogo() {
      return this.player.module.sponsor_logo;
    },
    language() {
      if (
        this.player &&
        this.player.appearance_settings &&
        this.player.appearance_settings.language
      ) {
        return this.player.appearance_settings.language;
      }

      return 'en';
    },
    showReward() {
      console.log(this.player.fan_redeem_page);
      if (
        this.player
        && isPlainObject(this.player.fan_redeem_page)
        && this.player.fan_redeem_page.constructor.name === 'RewardPage'
        && isPlainObject(this.player.fan_about_page)
        && this.player.fan_reward_page.constructor.name === 'RewardPage'
      ) {
        return true;
      }
      return false;
      //return this.player.key === '920788a8990df628364bef7ecd1c0788';
    },
    currentModuleIsPage() {
      return (
        this.currentModule && this.currentModule.type === PlayerModuleType.Page
      );
    },
    showBackButton() {
      if (this.isMobileLandscape) {
        return !this.openModuleRenderer;
      }
      return this.$store.getters['navigation/isInRoot'];
    },
    ...mapState({
      openModuleRenderer: (state) => state.navigation.openModuleRenderer,
    }),
  },
  watch: {
    currentModuleIsPage(val) {
      if (!val && this.$root.$refs.mainPlayer && this.mainPlayerIsLive) {
        this.$root.$refs.mainPlayer.player.play();
      }
    },
  },
  methods: {
    fetchData() {
      return window
        .fetch(
          `${process.env.VUE_APP_PLAYER_DETAILS_URL}${
            this.playerId
          }.smc?${Date.now()}`
        )
        .then((response) => response.json())
        .catch((e) => {
          console.error('Failed to retrieve Player details.', e);
        });
    },
    initPlayer(details) {
      this.player = new Player(details);
      if (this.player) {
        this.$store.commit('app/setKey', this.player.key);

        this.$store.dispatch('reward/init', {
          id: this.player.key,
          rootModule: this.player.module,
        });

        document.body.style.setProperty(
          '--primary-color',
          this.player.appearance_settings.button_color
        );
        this.$store.dispatch('navigation/setCurrentModule', this.player.module);

        if (this.player.font) {
          this.addFontFace(this.player.font);
        }

        this.initWsConnection();
        this.loadArcheo();
      }
    },
    initWsConnection() {
      if (this.wsClient !== null || forcedPlayer) {
        return;
      }

      this.wsClient = new WsClient({
        wsUrl: process.env.VUE_APP_CHAT_WS_URL,
        apiKey: this.player.key,
      });
      this.wsClient.addEventListener(WsClientEvents.INFO, this.onWsInfo);
      this.wsClient.addEventListener(
        WsClientEvents.JOINED,
        () => (this.isWsConnected = true)
      );
      this.wsClient.addEventListener(WsClientEvents.PLAYER_DETAILS, (details) =>
        this.initPlayer(details)
      );
      this.wsClient.addEventListener(
        WsClientEvents.STREAM_STATUS_CHANGED,
        (status) => {
          this.player.live_status = status;

          if (this.player.live_status !== PlayerLiveStatus.Live) {
            this.wsClient.pause();
          }
        }
      );

      this.wsClient.addEventListener(
        WsClientEvents.CHEER_RESULTS,
        ({ id, results }) => {
          this.$store.commit('cheer/setResults', { id, results });
        }
      );
      this.wsClient.addEventListener(WsClientEvents.CHEER, ({ data }) => {
        this.$store.commit('cheer/increase', {
          id: data.id,
          optionId: data.team_id,
          value: data.value,
        });
      });

      this.wsClient.addEventListener(
        WsClientEvents.POLL_RESULTS,
        ({ id, results }) => {
          this.$store.commit('poll/setResults', { id, results });
        }
      );
      this.$root.wsClient.addEventListener(
        WsClientEvents.POLL_VOTE,
        ({ data }) => {
          this.$store.commit('poll/increase', {
            id: data.poll_id,
            optionId: data.answer_id,
            value: data.votes,
          });
        }
      );

      this.wsClient.addEventListener(
        WsClientEvents.PRODUCT_QUANTITY,
        ({ productId, quantity }) => this.player.updateProductQuantity(productId, quantity)
      );
    },
    loadArcheo() {
      if ('bnnrsajs' in window) {
        return;
      }

      let archeo = { app: process.env.VUE_APP_ARCHEO_APP, queue: [] };
      this.emitter.on('aq', function (args) {
        archeo.queue.push(args);
      });

      setTimeout(() => {
        const script = document.createElement('script');
        script.src = process.env.VUE_APP_ARCHEO_SCRIPT_URL + '?vn=bnnrsajs';
        script.defer = true;
        script.onload = () => {
          archeo = window.bnnrsajs(archeo);
          archeo.pageview({ content_id: this.apikey });
        };
        document.head.appendChild(script);
      }, 1000);
    },
    onWsInfo(info) {
      this.viewers = info.activePlayerCount;
      this.waitingForWsInfo = false;
    },
    goBack() {
      this.$store.dispatch('navigation/goBack');
    },
    addFontFace(font) {
      const fontUrl = process.env.VUE_APP_FONT_MANAGER_URL;

      let fontFormat = 'opentype';
      if (font.mime_type.includes('ttf')) {
        fontFormat = 'truetype';
      } else if (font.mime_type.includes('woff2')) {
        fontFormat = 'woff2';
      } else if (font.mime_type.includes('woff')) {
        fontFormat = 'woff';
      } else if (font.mime_type.includes('eot')) {
        fontFormat = 'embedded-opentype';
      }

      let css = `
        @font-face {
          font-family: "${font.name}";
          src: url("${fontUrl}/${font.id}/regular") format("${fontFormat}");
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: "${font.name}";
          src: url("${fontUrl}/${font.id}/bold") format("${fontFormat}");
          font-weight: 900;
          font-style: normal;
          font-display: swap;
        }
        * {
          font-family: "${font.name}", Poppins, Helvetica, Arial, sans-serif !important;
        }
      `;

      const style = document.createElement('style');
      style.id = 'bannerse-custom-font--' + font.id;

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      this.$el.appendChild(style);
    },
    detectOrientation() {
      if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
        let orientation;
        try {
          // Note: screen.orientation is available only from iOS 16.4
          // https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation#browser_compatibility
          orientation = screen.orientation.type
        } catch (e) {
          orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
        }

        if (orientation.includes('landscape')) {
          this.isMobileLandscape = true;
        } else {
          this.isMobileLandscape = false;
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.detectOrientation();

      const app = document.getElementById('app');

      if (this.isDesktop) {
        const scale = window.innerHeight / (this.$el.offsetHeight + 50);
        if (scale < 1) {
          app.style.transform = 'scale(' + scale + ') translate(-50%, -50%)';
        } else {
          app.style.transform = 'translate(-50%, -50%)';
        }
      } else {
        app.style.transform = null;
      }
    },
    updateClock() {
      if (!this.$refs.clockFrame) {
        return;
      }

      const now = new Date();
      const hours = now.getHours();
      const minutes = ('' + now.getMinutes()).padStart(2, '0');
      this.$refs.clockFrame.innerHTML = hours + ':' + minutes;
    },
    onBackButtonClick() {
      if (this.rewardPageOpened) {
        this.closeModuleRenderer();
        return;
      }
      if (!this.$store.getters['navigation/isInRoot']) {
        return;
      }
      else {
        if (!this.wasInRoot) {
          this.wasInRoot = true;
        } else {
          this.closeModuleRenderer();
          return;
        }
      }
    },
    toggleModuleRenderer() {
      if (this.openModuleRenderer) {
        this.closeModuleRenderer();
        return;
      } else {
        this.wasInRoot = true;
        this.$store.dispatch('navigation/setOpenModuleRenderer', true);
      }
    },
    closeModuleRenderer() {
      this.isModuleRendererClosing = true;
      this.wasInRoot = false;
      this.$store.dispatch('navigation/setPath', [this.player.module]);
      this.rewardPageOpened = false;
      setTimeout(() => {
        this.$store.dispatch('navigation/setOpenModuleRenderer', false);
        this.isModuleRendererClosing = false;
      }, 200);
    },
  },
};
</script>
