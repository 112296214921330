const availableLanguages = {
  'en': 'English',
  'hu': 'Magyar',
};

let language = localStorage.getItem('language');
if (!language) {
  language = (navigator.language || navigator.userLanguage).split('-')[0];
}
if (!Object.keys(availableLanguages).includes(language)) {
  language = 'en';
}
window.localStorage.setItem('language', language);

const state = {
  key: null,
  isConnecting: false,
  isLoading: true,
  availableLanguages,
  language
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isConnecting(state) {
    return state.isConnecting;
  },
  availableLanguages(state) {
    return state.availableLanguages;
  },
  language(state) {
    return state.language;
  },
  key(state) {
    return state.key;
  },
};

const actions = {
};

const mutations = {
  isLoading(state, value) {
    state.isLoading = value;
  },
  isConnecting(state, value) {
    state.isConnecting = value;
  },
  language(state, value) {
    state.language = value;
    window.localStorage.setItem('language', state.language);
  },
  setKey(state, value) {
    state.key = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
