const translations = {
  'hu': require('@/translations/hu.json'),
  'en': require('@/translations/en.json'),
};

function replaceParameters(str, params) {
  if (params) {
    Object.keys(params).forEach(param => {
      str = str.replaceAll(param, params[param]);
    });
  }

  return str;
}

export default {
  methods: {
    tr(str, params) {
      if (translations[this.$root.language] && translations[this.$root.language][str]) {
        return replaceParameters(translations[this.$root.language][str], params);
      }

      if (this.$root.language !== 'en' && process.env.NODE_ENV !== 'production') {
        console.warn('Missing "' + str + '" ' + this.$root.language + ' translation.');
      }

      if (translations['en'] && translations['en'][str]) {
        return replaceParameters(translations['en'][str], params);
      }

      return replaceParameters(str, params);
    },
    translateOptionList(list, labelKey) {
      if (!labelKey) {
        labelKey = 'label';
      }

      let copy = JSON.parse(JSON.stringify(list));
      return copy.map(item => {
        item[labelKey] = this.tr(item[labelKey]);
        return item;
      });
    },
  },
};