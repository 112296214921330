<template>
  <div class="text-animation--wave">
    <span
      v-for="(character, index) in textArray"
      :key="`taw-${index}-${character}`"
      :style="{'animation-delay': `${(bezierFraction(index))}ms`}"
      v-html="character"
    />
  </div>
</template>

<script>
export default {
  name: 'TextAnimationWave',
  props: {
    text: {
      type: String,
      required: true,
    },
    easing: {
      type: String,
      required: false,
      default: 'ease-in-out',
      validator: (v) => ['ease', 'ease-in', 'ease-out', 'linear', 'ease-reversed', 'ease-in-out'].includes(v),
    },
    delay: {
      type: Number,
      required: false,
      default: 25,
    },
    duration: {
      type: Number,
      required: false,
      default: 1000,
    },
  },
  data() {
    return {
      bezierEasingFn: null
    };
  },
  computed: {
    textArray() {
      const arr = [...new Intl.Segmenter().segment(this.text)].map(x => x.segment)
      return arr.map((c) => c === ' ' ? '&nbsp;' : c)
    },
  },
  methods: {
    bezierFraction(characterIndex) {
      const x = characterIndex / this.textArray.length;
      return this.bezierEasingFn(x) * this.duration
    },
    bezierEase(p1x = 0, p1y = 0, p2x = 0, p2y = 0) {
      const p1 = {
        x: p1x,
        y: p1y,
      }

      const p2 = {
        x: p2x,
        y: p2y,
      }

      const arr = [0,];
      const stepArr = 0.05;
      const stepBaz = 0.05;
      const baz = bezierEase0(p1, p2);
      let [prevBaz, nextBaz, tBaz,] = [{ x: 0, y: 0, }, baz(stepBaz), stepBaz,];
      for (let x = stepArr; x <= 1 - stepArr / 2; x += stepArr) {
          while (x > nextBaz.x && tBaz < 2) {
              prevBaz = nextBaz;
              tBaz += stepBaz;
              nextBaz = baz(tBaz);
          }
          arr.push(linInp(x, prevBaz.x, nextBaz.x, prevBaz.y, nextBaz.y));
      }
      arr.push(1);

      return (fraction) => {
        const iLeft = Math.min(Math.max(Math.floor(fraction / stepArr), 0), arr.length - 2);
        return linInp(fraction, iLeft * stepArr, (iLeft + 1) * stepArr, arr[iLeft], arr[iLeft + 1]);
      };

      function linInp(x, xFrom, xTo, yFrom, yTo) {
        return (x - xFrom) / (xTo - xFrom) * (yTo - yFrom) + yFrom;
      }

      // https://stackoverflow.com/questions/16227300, https://github.com/gre/bezier-easing/blob/master/src/index.js
      function bezierEase0(p1, p2) {
        const cX = 3 * p1.x;                            // const cX = 3 * (p1.x - p0.x);
        const cY = 3 * p1.y;                            // const cY = 3 * (p1.y - p0.y);
        const bX = 3 * (p2.x - p1.x) - cX;              // const bX = 3 * (p2.x - p1.x) - cX;
        const bY = 3 * (p2.y - p1.y) - cY;              // const bY = 3 * (p2.y - p1.y) - cY;
        const aX = 1 - cX - bX;                         // const aX = p3.x - p0.x - cX - bX;
        const aY = 1 - cY - bY;                         // const aY = p3.y - p0.y - cY - bY;
        return (t) => {
          const x = ((aX * t + bX) * t + cX) * t;     // const x = ((aX * t + bX) * t + cX) * t + p0.x;
          const y = ((aY * t + bY) * t + cY) * t;     // const y = ((aY * t + bY) * t + cY) * t + p0.y;
          return { x, y, };
        };
      }
    },
  },
  watch: {
    textArray: {
      immediate: true,
      handler() {
        switch (this.easing) {
          case 'ease':
            this.bezierEasingFn = this.bezierEase(0.25, 0.1, 0.25, 1)
            break;

          case 'ease-reversed':
            this.bezierEasingFn = this.bezierEase(0.9, 0, 0.75, 0,9)
            break;

          case 'ease-in':
            this.bezierEasingFn = this.bezierEase(0.42, 0, 1, 1)
            break;

          case 'ease-out':
            this.bezierEasingFn = this.bezierEase(0, 0, 0.58, 1)
            break;

          case 'ease-in-out':
            this.bezierEasingFn = this.bezierEase(0.15, 0.75, 0.85, 0.15)
            break;

          case 'linear':
          default:
            this.bezierEasingFn = this.bezierEase(0, 0, 1, 1)
            break;
        }
      }
    }
  }
}
</script>
