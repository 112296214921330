<template>
  <div class="product-price">
    <div v-if="showFromPrice" class="price" v-html="fromPriceLabel" />

    <template v-else-if="showPrice">
      <template v-if="renderableProduct.sale_price !== null">
        <div class="price price--sale" v-html="formatPrice(renderableProduct.sale_price, currency)" />
        <div v-if="renderableProduct.price !== null" class="original-price" v-html="formatPrice(renderableProduct.price, currency)" />
      </template>
      <template v-else>
        <div class="price" v-html="formatPrice(renderableProduct.price, currency)" />
      </template>
    </template>
  </div>
</template>

<script>
import TranslatorMixin from '@/mixins/Translator';
import { formatPrice } from '@/utils/formatters';
import { PlayerCardProduct } from 'smucio-stream-entities';

export default {
  name: 'ProductPrice',
  mixins: [ TranslatorMixin ],
  props: {
    product: {
      type: PlayerCardProduct,
      required: true,
    },
    selectedVariation: {
      type: PlayerCardProduct || null,
      required: false,
      default: () => null,
    },
  },
  computed: {
    renderableProduct() {
      if (this.selectedVariation) {
        return this.selectedVariation;
      }

      return this.product;
    },
    hasVariations() {
      return this.product.variations.length !== 0;
    },
    currency() {
      if (this.product.currency) {
        return this.product.currency;
      }

      if (this.hasVariations) {
        const productWithCurrency = this.product.variations.find(product => product.currency);
        if (productWithCurrency) {
          return productWithCurrency.currency;
        }
      }

      return null;
    },
    showPrice() {
      return this.renderableProduct.sale_price !== null || this.renderableProduct.price !== null;
    },
    showFromPrice() {
      return this.lowestPrice !== this.highestPrice && !this.selectedVariation;
    },
    fromPriceLabel() {
      return this.tr('From :price', { ':price': formatPrice(this.lowestPrice, this.currency) });
    },
    variationPrices() {
      if (!this.hasVariations) {
        return [];
      }

      return this.product.variations
        .map(v => v.sale_price ?? v.price)
        .filter(price => price !== 0);
    },
    lowestPrice() {
      if (this.variationPrices.length === 0) {
        return null;
      }

      return Math.min(...this.variationPrices);
    },
    highestPrice() {
      if (this.variationPrices.length === 0) {
        return null;
      }

      return Math.max(...this.variationPrices);
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
