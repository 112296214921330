const state = {
  id: null,
  points: null,
  max: null,
  isRedeemed: false,
  isClaimed: false,
  rewards: [],
  _modules_reusability: {}
};

const getters = {
  points(state) {
    return state.points;
  },
  max(state) {
    return state.max;
  },
  isRedeemed(state) {
    return state.isRedeemed;
  },
  isClaimed(state) {
    return state.isClaimed;
  },
};

const LS_REWARD_KEY_PREFIX = 'reward--';
const LS_BASE64_MAGIC_NUMBER = 6;

const actions = {
  init({ commit }, { id, rootModule }) {
    commit('init', { id, rootModule })
  },
  creditPoints({ commit }, module) {
    commit('addPoints', module);
  },
  setIsRedeemed({ commit }, value) {
    commit('setIsRedeemed', value);
  }
};

const mutations = {
  init(state, { id, rootModule }) {
    // module as PlayerModule
    state.id = id;

    const allRewards = getChildrenRewards(rootModule);
    if (allRewards.length === 0) {
      return;
    }

    let max = 0;
    let points = 0;
    let modulesReusability = {};

    const storedState = readItem(id);

    const brokenReactivityObject = JSON.parse(JSON.stringify(allRewards));
    state.rewards = brokenReactivityObject;

    for (let reward of brokenReactivityObject) {
      modulesReusability[reward.machine_name] = reward.reusability;

      max += (reward.value * reward.reusability)

      if (
        storedState !== null
        && typeof storedState.modulesReusability[reward.machine_name] !== 'undefined'
      ) {
        points += (reward.reusability - storedState.modulesReusability[reward.machine_name]) * reward.value;

        modulesReusability[reward.machine_name] = storedState.modulesReusability[reward.machine_name];
      }
    }

    state.max = max;
    state.points = points;
    state._modules_reusability = modulesReusability;

    if (state.max == state.points) {
      state.isRedeemed = storedState.isRedeemed;
      state.isClaimed = storedState.isClaimed;
    }
  },
  addPoints(state, { module }) {
    if (typeof state._modules_reusability[module.reward.machine_name] === 'undefined') {
      state._modules_reusability[module.reward.machine_name] = module.reward.reusability;
    }

    if (state._modules_reusability[module.reward.machine_name] < 1) {
      return;
    }

    state.points += module.reward.value;
    state._modules_reusability[module.reward.machine_name]--;

    writeItem(state.id, prepareStoreData());
  },
  setIsRedeemed(state, value) {
    state.isRedeemed = value === 1;
    state.isClaimed = value === 2;

    writeItem(state.id, prepareStoreData());
  },
};

const getChildrenRewards = (module) => {
  let rewards = [];

  if (
    typeof module.reward !== 'undefined'
    && module.reward !== null
  ) {
    rewards.push(module.reward)
  }

  if (
    typeof module.children !== 'undefined'
    && Array.isArray(module.children)
  ) {
    for (let submodule of module.children) {
      rewards = rewards.concat(...getChildrenRewards(submodule));
    }
  }

  return rewards;
}

const prepareStoreData = () => {
  return {
    isRedeemed: state.isRedeemed,
    isClaimed: state.isClaimed,
    modulesReusability: state._modules_reusability,
  }
}

const readItem = (id) => {
  if (typeof window.localStorage === 'undefined') {
    return null;
  }

  const enchantedValue = window.localStorage.getItem(LS_REWARD_KEY_PREFIX + id.toString());
  if (enchantedValue === null) {
    return null;
  }

  try {
    const cleansedValue = atob(enchantedValue.substring(LS_BASE64_MAGIC_NUMBER));
    return JSON.parse(cleansedValue);
  } catch (e) {
    console.error('[Reward] Failed to parse reward.', e)
    return null;
  }
}

const writeItem = (id, data) => {
  if (typeof window.localStorage === 'undefined') {
    return null;
  }

  const encodedValue = btoa(JSON.stringify(data));
  const enchantedValue = String(makeRandomHash(LS_BASE64_MAGIC_NUMBER)) + String(encodedValue);
  window.localStorage.setItem(LS_REWARD_KEY_PREFIX + id.toString(), enchantedValue);
}

const makeRandomHash = (length = 4) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
