<template>
  <form ref="wrapper" class="stripe-form" @submit="pay" autocomplete="off">
    <div class="stripe-form--form">
      <div v-show="paymentRequestButtonVisible" ref="payment-request-button" />
      <div v-if="paymentRequestButtonVisible" class="button-separator">
        {{ tr('or') }}
      </div>
      <div class="stripe-form-inputs">
        <div :class="{ 'card-number-container': true, 'with-icons': this.cardTypeIconsVisible }">
          <div ref="card-number" />
        </div>
        <div class="card-details-container">
          <div class="card-expiry" ref="card-expiry" />
          <div class="card-cvc" ref="card-cvc" />
        </div>

        <input
          v-model="billingName"
          type="text"
          :placeholder="tr('Name')"
          :disabled="isLoading"
        >
        <input
          v-model="billingEmail"
          type="email"
          :placeholder="tr('Email')"
          :disabled="isLoading"
        >
      </div>

      <div class="block-label">
        {{ tr('Billing address') }}
      </div>

      <div class="stripe-form-inputs">
        <v-select
          v-model="billingCountry"
          :placeholder="tr('Country')"
          :options="countries"
          :reduce="item => item.value"
          :disabled="isLoading"
        />
        <div class="postal-code-and-city-input-container">
          <input
            v-model="billingPostalCode"
            type="text"
            class="postal-code-input"
            :placeholder="tr('Postal code')"
            :disabled="isLoading"
          >
          <input
            v-model="billingCity"
            type="text"
            :placeholder="tr('City')"
            :disabled="isLoading"
          >
        </div>
        <input
          v-model="billingAddress"
          type="text"
          :placeholder="tr('Address')"
          :disabled="isLoading"
        >
      </div>
    </div>
    <div class="stripe-form--action">
      <button type="submit" :class="payButtonClasses">
        <i v-if="isLoading" class="icon loader white" />
        <template v-else>
          {{ tr('Pay now') }}: {{ formattedPrice }}
        </template>
      </button>
    </div>

    <transition name="fade-fast" mode="out-in">
      <div v-if="successMessageIsVisible" class="success-popup">
        <div class="content">
          <div class="image" />
          <div class="complete-message">{{ tr('successful payment') }}</div>

          <div class="order-id">{{ tr('Order ID') }}: {{ purchase.hashed_id }}</div>
          <div class="complete-message--long">{{ tr('We have sent the details of your order and the invoice to the provided email address.') }}</div>
        </div>

        <button class="button subscribe-button" @click="() => goBack()">
          {{ tr('Got it') }}
        </button>
      </div>
    </transition>
  </form>
</template>

<script>
import { formatPrice } from '@/utils/formatters';
import { toast } from 'vue3-toastify';
import { PlayerCardProduct } from 'smucio-stream-entities'

export default {
  name: 'StripeForm',
  props: {
    product: {
      type: PlayerCardProduct,
      required: true
    },
  },
  data: () => ({
    isMounted: false,
    stripe: null,
    elements: null,
    cardNumberElement: null,
    paymentRequestButtonVisible: false,
    cardTypeIconsVisible: true,
    billingName: '',
    billingEmail: '',
    billingCountry: '',
    billingPostalCode: '',
    billingCity: '',
    billingAddress: '',
    isValidCardNumber: false,
    isValidExpiry: false,
    isValidCvc: false,
    isLoading: false,
    stripeErrorMessage: null,
    successMessageIsVisible: false,
    purchase: null,

    style: {
      base: {
        iconColor: '#ffffff',
        color: '#ffffff',
        lineHeight: '46px',
        fontWeight: 400,
        fontFamily: 'inherit',
        fontSmoothing: 'antialiased',
        fontSize: '15px',
        letterSpacing: '0.1px',
        '::placeholder': {
          color: '#ffffff7f'
        },
        ':-webkit-autofill': {
          color: '#ffffff7f'
        },
      },
    }
  }),
  computed: {
    payButtonClasses() {
      return {
        pay: true,
        button: true,
        'subscribe-button': true,
        disable: this.isLoading
      }
    },
    isValidEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.billingEmail)
    },
    isValidForm() {
      return this.isValidCardNumber
        && this.isValidExpiry
        && this.isValidCvc
        && this.billingName
        && this.billingAddress
        && this.isValidEmail;
    },
    price() {
      return parseFloat(this.product.sale_price !== null ? this.product.sale_price : this.product.price);
    },
    formattedPrice() {
      if (isNaN(this.price)) {
        return null;
      }

      return formatPrice(this.price, this.product.currency);
    },
    countries() {
      return countries;
    },
  },
  mounted() {
    this.isMounted = true;
    this.init();

    this.$refs.wrapper.querySelectorAll('input').forEach(input => {
      input.setAttribute('autocomplete', 'off-' + Math.random());
    });
  },
  beforeUnmount() {
    this.isMounted = false;
  },
  methods: {
    init() {
      const stripeLoader = require('@stripe/stripe-js').loadStripe;

      stripeLoader(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
        .then((stripe) => {
          this.stripe = stripe

          this.elements = this.stripe.elements({ locale: this.$root.player.appearance_settings.language })

          this.cardNumberElement = this.elements.create('cardNumber', { style: this.style })
          const cardExpiryElement = this.elements.create('cardExpiry', { style: this.style })
          const cardCvcElement = this.elements.create('cardCvc', { style: this.style })

          if (!this.isMounted) {
            return;
          }

          this.cardNumberElement.mount(this.$refs['card-number'])
          cardExpiryElement.mount(this.$refs['card-expiry'])
          cardCvcElement.mount(this.$refs['card-cvc'])

          this.cardNumberElement.on('change', this.onChangeStripeElement)
          cardExpiryElement.on('change', this.onChangeStripeElement)
          cardCvcElement.on('change', this.onChangeStripeElement)

          setTimeout(() => this.paymentRequestInit(), 500);
        });
    },
    onChangeStripeElement(e) {
      const hasError = !!(e.error && e.error.message)
      const isValid = !!(!hasError && e.complete && !e.empty)

      if (e.elementType === 'cardNumber') {
        this.isValidCardNumber = isValid
      }
      else if (e.elementType === 'cardExpiry') {
        this.isValidExpiry = isValid
      }
      else if (e.elementType === 'cardCvc') {
        this.isValidCvc = isValid
      }

      if (hasError && !this.stripeErrorMessage) {
        this.stripeErrorMessage = e.error.message
      }
      if (!hasError) {
        this.stripeErrorMessage = null
      }
    },
    async paymentRequestInit() {
      const paymentRequest = this.stripe.paymentRequest({
        country: 'HU',
        currency: this.product.currency.toLowerCase(),
        total: {
          label: this.product.name,
          amount: this.price * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then(result => {
        if (!result) {
          return;
        }
        if (result.link) {
          this.cardTypeIconsVisible = false;
        }

        const paymentRequestButton = this.elements.create('paymentRequestButton', { paymentRequest });

        if (!this.isMounted) {
          return;
        }

        paymentRequestButton.mount(this.$refs['payment-request-button'])
        this.paymentRequestButtonVisible = true;

        paymentRequest.on('paymentmethod', (ev) => this.paymentRequestComplete(ev));
      });
    },
    async paymentRequestComplete(ev) {
      const paymentMethod = ev.paymentMethod;
      const billingDetails = paymentMethod.billing_details;

      this.billingName = billingDetails.name;
      this.billingEmail = billingDetails.email;
      this.billingCountry = billingDetails.address.country;
      this.billingPostalCode = billingDetails.address.postal_code;
      this.billingCity = billingDetails.address.city;
      this.billingAddress = billingDetails.address.line1;
      if (billingDetails.address.line2 && billingDetails.address.line2 !== 'null') {
        this.billingAddress += ' ' + billingDetails.address.line2;
      }

      this.pay(ev, paymentMethod.id);
    },
    validate() {
      if (!this.isValidEmail) {
        toast.error(this.tr('The email address you provided is incorrect'));
        return false;
      }
      if (!this.billingName) {
        toast.error(this.tr('The Name field must be filled in'));
        return false;
      }
      if (!this.billingCountry || !this.billingPostalCode || !this.billingCity || !this.billingAddress) {
        toast.error(this.tr('All fields of the billing address must be filled in'));
        return false;
      }
      if (this.stripeErrorMessage !== null) {
        toast.error(this.stripeErrorMessage);
        return false;
      }
      /* if (!this.isValidCardNumber || !this.isValidExpiry || !this.isValidCvc) {
        toast.error(this.tr('Incorrect or incomplete card data'));
        return false;
      } */

      return true;
    },
    async pay(e, paymentMethodId) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      if (this.isLoading || this.successMessageIsVisible || !this.validate()) {
        return;
      }

      this.isLoading = true

      try {
        const requestPayload = {
          player_id: this.$store.getters['app/key'],
          items: [
            {
              product_id: this.product.id,
              quantity: 1,
            },
          ],
          name: this.billingName,
          email: this.billingEmail,
          country: this.billingCountry,
          city: this.billingCity,
          postal_code: this.billingPostalCode,
          address: this.billingAddress,
        };

        if (paymentMethodId) {
          requestPayload.payment_method_id = paymentMethodId;
        }

        const url = process.env.VUE_APP_PURCHASE_API_URL + '/purchase'
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestPayload)
        }

        const resp = await fetch(url, requestOptions).then((response) => response.json());
        if (resp.code !== 200) {
          throw new Error(resp.payload);
        }

        this.purchase = resp.payload.purchase;

        let paymentMethod = paymentMethodId;
        if (!paymentMethod) {
          paymentMethod = {
            card: this.cardNumberElement,
            billing_details: {
              name: this.billingName,
              email: this.billingEmail,
              address: {
                country: this.billingCountry,
                line1: this.billingAddress,
                city: this.billingCity,
                postal_code: this.billingPostalCode,
              },
            },
          };
        }

        const confirmResp = await this.stripe.confirmCardPayment(
          resp.payload.client_secret,
          { payment_method: paymentMethod },
          { handleActions: !paymentMethodId }
        );
        if (confirmResp.error) {
          throw new Error(confirmResp.error.message);
        }

        this.showSuccessPopup();
        e.complete('success');
      }
      catch (err) {
        toast.error(err.message);
        console.error(err);
        e.complete('fail');
      }

      this.isLoading = false;
    },
    showSuccessPopup() {
      this.successMessageIsVisible = true;
    },
    goBack() {
      this.$store.dispatch('navigation/goBack');
      this.$store.dispatch('navigation/goBack');
    },
  }
}

const countries = [
    {"value": "AF", "label": "Afghanistan"},
    {"value": "AL", "label": "Albania"},
    {"value": "DZ", "label": "Algeria"},
    {"value": "AS", "label": "American Samoa"},
    {"value": "AD", "label": "Andorra"},
    {"value": "AO", "label": "Angola"},
    {"value": "AI", "label": "Anguilla"},
    {"value": "AQ", "label": "Antarctica"},
    {"value": "AG", "label": "Antigua and Barbuda"},
    {"value": "AR", "label": "Argentina"},
    {"value": "AM", "label": "Armenia"},
    {"value": "AW", "label": "Aruba"},
    {"value": "AU", "label": "Australia"},
    {"value": "AT", "label": "Austria"},
    {"value": "AZ", "label": "Azerbaijan"},
    {"value": "BS", "label": "Bahamas"},
    {"value": "BH", "label": "Bahrain"},
    {"value": "BD", "label": "Bangladesh"},
    {"value": "BB", "label": "Barbados"},
    {"value": "BY", "label": "Belarus"},
    {"value": "BE", "label": "Belgium"},
    {"value": "BZ", "label": "Belize"},
    {"value": "BJ", "label": "Benin"},
    {"value": "BM", "label": "Bermuda"},
    {"value": "BT", "label": "Bhutan"},
    {"value": "BO", "label": "Bolivia"},
    {"value": "BQ", "label": "Bonaire, Sint Eustatius and Saba"},
    {"value": "BA", "label": "Bosnia and Herzegovina"},
    {"value": "BW", "label": "Botswana"},
    {"value": "BV", "label": "Bouvet Island"},
    {"value": "BR", "label": "Brazil"},
    {"value": "IO", "label": "British Indian Ocean Territory"},
    {"value": "BN", "label": "Brunei Darussalam"},
    {"value": "BG", "label": "Bulgaria"},
    {"value": "BF", "label": "Burkina Faso"},
    {"value": "BI", "label": "Burundi"},
    {"value": "CV", "label": "Cabo Verde"},
    {"value": "KH", "label": "Cambodia"},
    {"value": "CM", "label": "Cameroon"},
    {"value": "CA", "label": "Canada"},
    {"value": "KY", "label": "Cayman Islands"},
    {"value": "CF", "label": "Central African Republic"},
    {"value": "TD", "label": "Chad"},
    {"value": "CL", "label": "Chile"},
    {"value": "CN", "label": "China"},
    {"value": "CX", "label": "Christmas Island"},
    {"value": "CC", "label": "Cocos (Keeling) Islands"},
    {"value": "CO", "label": "Colombia"},
    {"value": "KM", "label": "Comoros"},
    {"value": "CG", "label": "Congo"},
    {"value": "CD", "label": "Congo, Democratic Republic of the"},
    {"value": "CK", "label": "Cook Islands"},
    {"value": "CR", "label": "Costa Rica"},
    {"value": "HR", "label": "Croatia"},
    {"value": "CU", "label": "Cuba"},
    {"value": "CW", "label": "Curaçao"},
    {"value": "CY", "label": "Cyprus"},
    {"value": "CZ", "label": "Czechia"},
    {"value": "DK", "label": "Denmark"},
    {"value": "DJ", "label": "Djibouti"},
    {"value": "DM", "label": "Dominica"},
    {"value": "DO", "label": "Dominican Republic"},
    {"value": "EC", "label": "Ecuador"},
    {"value": "EG", "label": "Egypt"},
    {"value": "SV", "label": "El Salvador"},
    {"value": "GQ", "label": "Equatorial Guinea"},
    {"value": "ER", "label": "Eritrea"},
    {"value": "EE", "label": "Estonia"},
    {"value": "SZ", "label": "Eswatini"},
    {"value": "ET", "label": "Ethiopia"},
    {"value": "FK", "label": "Falkland Islands (Malvinas)"},
    {"value": "FO", "label": "Faroe Islands"},
    {"value": "FJ", "label": "Fiji"},
    {"value": "FI", "label": "Finland"},
    {"value": "FR", "label": "France"},
    {"value": "GF", "label": "French Guiana"},
    {"value": "PF", "label": "French Polynesia"},
    {"value": "TF", "label": "French Southern Territories"},
    {"value": "GA", "label": "Gabon"},
    {"value": "GM", "label": "Gambia"},
    {"value": "GE", "label": "Georgia"},
    {"value": "DE", "label": "Germany"},
    {"value": "GH", "label": "Ghana"},
    {"value": "GI", "label": "Gibraltar"},
    {"value": "GR", "label": "Greece"},
    {"value": "GL", "label": "Greenland"},
    {"value": "GD", "label": "Grenada"},
    {"value": "GP", "label": "Guadeloupe"},
    {"value": "GU", "label": "Guam"},
    {"value": "GT", "label": "Guatemala"},
    {"value": "GG", "label": "Guernsey"},
    {"value": "GN", "label": "Guinea"},
    {"value": "GW", "label": "Guinea-Bissau"},
    {"value": "GY", "label": "Guyana"},
    {"value": "HT", "label": "Haiti"},
    {"value": "HM", "label": "Heard Island and McDonald Islands"},
    {"value": "VA", "label": "Holy See"},
    {"value": "HN", "label": "Honduras"},
    {"value": "HK", "label": "Hong Kong"},
    {"value": "HU", "label": "Hungary"},
    {"value": "IS", "label": "Iceland"},
    {"value": "IN", "label": "India"},
    {"value": "ID", "label": "Indonesia"},
    {"value": "IR", "label": "Iran"},
    {"value": "IQ", "label": "Iraq"},
    {"value": "IE", "label": "Ireland"},
    {"value": "IM", "label": "Isle of Man"},
    {"value": "IL", "label": "Israel"},
    {"value": "IT", "label": "Italy"},
    {"value": "JM", "label": "Jamaica"},
    {"value": "JP", "label": "Japan"},
    {"value": "JE", "label": "Jersey"},
    {"value": "JO", "label": "Jordan"},
    {"value": "KZ", "label": "Kazakhstan"},
    {"value": "KE", "label": "Kenya"},
    {"value": "KI", "label": "Kiribati"},
    {"value": "KP", "label": "Korea, Democratic People's Republic of"},
    {"value": "KR", "label": "Korea, Republic of"},
    {"value": "KW", "label": "Kuwait"},
    {"value": "KG", "label": "Kyrgyzstan"},
    {"value": "LA", "label": "Lao People's Democratic Republic"},
    {"value": "LV", "label": "Latvia"},
    {"value": "LB", "label": "Lebanon"},
    {"value": "LS", "label": "Lesotho"},
    {"value": "LR", "label": "Liberia"},
    {"value": "LY", "label": "Libya"},
    {"value": "LI", "label": "Liechtenstein"},
    {"value": "LT", "label": "Lithuania"},
    {"value": "LU", "label": "Luxembourg"},
    {"value": "MO", "label": "Macao"},
    {"value": "MG", "label": "Madagascar"},
    {"value": "MW", "label": "Malawi"},
    {"value": "MY", "label": "Malaysia"},
    {"value": "MV", "label": "Maldives"},
    {"value": "ML", "label": "Mali"},
    {"value": "MT", "label": "Malta"},
    {"value": "MH", "label": "Marshall Islands"},
    {"value": "MQ", "label": "Martinique"},
    {"value": "MR", "label": "Mauritania"},
    {"value": "MU", "label": "Mauritius"},
    {"value": "YT", "label": "Mayotte"},
    {"value": "MX", "label": "Mexico"},
    {"value": "FM", "label": "Micronesia (Federated States of)"},
    {"value": "MD", "label": "Moldova"},
    {"value": "MC", "label": "Monaco"},
    {"value": "MN", "label": "Mongolia"},
    {"value": "ME", "label": "Montenegro"},
    {"value": "MS", "label": "Montserrat"},
    {"value": "MA", "label": "Morocco"},
    {"value": "MZ", "label": "Mozambique"},
    {"value": "MM", "label": "Myanmar"},
    {"value": "NA", "label": "Namibia"},
    {"value": "NR", "label": "Nauru"},
    {"value": "NP", "label": "Nepal"},
    {"value": "NL", "label": "Netherlands"},
    {"value": "NC", "label": "New Caledonia"},
    {"value": "NZ", "label": "New Zealand"},
    {"value": "NI", "label": "Nicaragua"},
    {"value": "NE", "label": "Niger"},
    {"value": "NG", "label": "Nigeria"},
    {"value": "NU", "label": "Niue"},
    {"value": "NF", "label": "Norfolk Island"},
    {"value": "MK", "label": "North Macedonia"},
    {"value": "MP", "label": "Northern Mariana Islands"},
    {"value": "NO", "label": "Norway"},
    {"value": "OM", "label": "Oman"},
    {"value": "PK", "label": "Pakistan"},
    {"value": "PW", "label": "Palau"},
    {"value": "PS", "label": "Palestine"},
    {"value": "PA", "label": "Panama"},
    {"value": "PG", "label": "Papua New Guinea"},
    {"value": "PY", "label": "Paraguay"},
    {"value": "PE", "label": "Peru"},
    {"value": "PH", "label": "Philippines"},
    {"value": "PN", "label": "Pitcairn"},
    {"value": "PL", "label": "Poland"},
    {"value": "PT", "label": "Portugal"},
    {"value": "PR", "label": "Puerto Rico"},
    {"value": "QA", "label": "Qatar"},
    {"value": "RE", "label": "Réunion"},
    {"value": "RO", "label": "Romania"},
    {"value": "RU", "label": "Russia"},
    {"value": "RW", "label": "Rwanda"},
    {"value": "BL", "label": "Saint Barthélemy"},
    {"value": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha"},
    {"value": "KN", "label": "Saint Kitts and Nevis"},
    {"value": "LC", "label": "Saint Lucia"},
    {"value": "MF", "label": "Saint Martin (French part)"},
    {"value": "PM", "label": "Saint Pierre and Miquelon"},
    {"value": "VC", "label": "Saint Vincent and the Grenadines"},
    {"value": "WS", "label": "Samoa"},
    {"value": "SM", "label": "San Marino"},
    {"value": "ST", "label": "Sao Tome and Principe"},
    {"value": "SA", "label": "Saudi Arabia"},
    {"value": "SN", "label": "Senegal"},
    {"value": "RS", "label": "Serbia"},
    {"value": "SC", "label": "Seychelles"},
    {"value": "SL", "label": "Sierra Leone"},
    {"value": "SG", "label": "Singapore"},
    {"value": "SX", "label": "Sint Maarten (Dutch part)"},
    {"value": "SK", "label": "Slovakia"},
    {"value": "SI", "label": "Slovenia"},
    {"value": "SB", "label": "Solomon Islands"},
    {"value": "SO", "label": "Somalia"},
    {"value": "ZA", "label": "South Africa"},
    {"value": "GS", "label": "South Georgia and the South Sandwich Islands"},
    {"value": "SS", "label": "South Sudan"},
    {"value": "ES", "label": "Spain"},
    {"value": "LK", "label": "Sri Lanka"},
    {"value": "SD", "label": "Sudan"},
    {"value": "SR", "label": "Suriname"},
    {"value": "SJ", "label": "Svalbard and Jan Mayen"},
    {"value": "SE", "label": "Sweden"},
    {"value": "CH", "label": "Switzerland"},
    {"value": "SY", "label": "Syrian Arab Republic"},
    {"value": "TW", "label": "Taiwan"},
    {"value": "TJ", "label": "Tajikistan"},
    {"value": "TZ", "label": "Tanzania"},
    {"value": "TH", "label": "Thailand"},
    {"value": "TL", "label": "Timor-Leste"},
    {"value": "TG", "label": "Togo"},
    {"value": "TK", "label": "Tokelau"},
    {"value": "TO", "label": "Tonga"},
    {"value": "TT", "label": "Trinidad and Tobago"},
    {"value": "TN", "label": "Tunisia"},
    {"value": "TR", "label": "Turkey"},
    {"value": "TM", "label": "Turkmenistan"},
    {"value": "TC", "label": "Turks and Caicos Islands"},
    {"value": "TV", "label": "Tuvalu"},
    {"value": "UG", "label": "Uganda"},
    {"value": "UA", "label": "Ukraine"},
    {"value": "AE", "label": "United Arab Emirates"},
    {"value": "GB", "label": "United Kingdom"},
    {"value": "US", "label": "United States"},
    {"value": "UM", "label": "United States Minor Outlying Islands"},
    {"value": "UY", "label": "Uruguay"},
    {"value": "UZ", "label": "Uzbekistan"},
    {"value": "VU", "label": "Vanuatu"},
    {"value": "VE", "label": "Venezuela"},
    {"value": "VN", "label": "Viet Nam"},
    {"value": "VG", "label": "Virgin Islands (British)"},
    {"value": "VI", "label": "Virgin Islands (U.S.)"},
    {"value": "WF", "label": "Wallis and Futuna"},
    {"value": "EH", "label": "Western Sahara"},
    {"value": "YE", "label": "Yemen"},
    {"value": "ZM", "label": "Zambia"},
    {"value": "ZW", "label": "Zimbabwe"}
];
</script>


<!--
2024.03.01    62.75   52.16   12.4% (7.78kg)
2024.06.24    65.25   53.24   14.0% (9.13kg)
              +2.5kg  +1.08kg +1.35kg

2024.06.25    64.50   52.79   13.8% (8.9kg)
              +1.75kg +0.63kg +1.1kg
2024.06.28    64.95   53.17   13.7% (9.03kg)
              +2.2kg  +1.01kg +1.25kg
-->
