<template>
  <button :class="{ 'back-button': true, 'hidden': hidden }" @click="goBack()">
    <i class="icon back white" />
  </button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    hidden: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  methods: {
    goBack() {
      this.$store.dispatch('navigation/goBack');
    },
  },
};
</script>
