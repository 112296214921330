<template>
  <component
    :is="wrapperTag"
    :class="wrapperClasses"
    :style="moduleStyle"
    v-bind="wrapperElementProperties"
    @click="() => onClick()"
  >
    <img
      v-if="imageUrl"
      :src="imageUrl"
      :class="coverImageClasses"
    />

    <img v-if="showIcon" :src="playerModule.icon" class="icon" />
    <div class="textual">
      <div
        v-if="title"
        v-html="title"
        class="module-title"
      />

      <product-price
        v-if="isProduct && playerModule.product"
        class="module-description"
        :product="playerModule.product"
      />
      <div
        v-else-if="playerModule.description"
        class="module-description"
        v-html="playerModule.description"
      />

      <div
        v-if="showExtraText"
        v-html="playerModule.extra_text"
        class="module-extra-text"
      />
    </div>
  </component>
</template>

<script>
import ProductPrice from '@/components/ProductPrice.vue';

import { PlayerModule, PlayerModuleSkin, PlayerModuleType } from 'smucio-stream-entities';

export default {
  name: 'ModuleComponent',
  components: {
    ProductPrice,
  },
  props: {
    playerModule: {
      type: PlayerModule,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    wrapperTag() {
      return this.playerModule.type === PlayerModuleType.Link ? 'a' : 'div';
    },
    wrapperElementProperties() {
      if (this.wrapperTag === 'a') {
        return {
          'href': this.playerModule.link,
          'target': '_blank',
        };
      }

      return {};
    },
    type() {
      return this.playerModule.type;
    },
    skin() {
      return this.playerModule.skin;
    },
    wrapperClasses() {
      const classes = {
        'module': true,
      };

      classes['module--' + this.skin.replaceAll('_', '-')] = true;
      classes['module--' + this.type.replaceAll('_', '-')] = true;
      classes['gloss-effect'] = this.skin === PlayerModuleSkin.Box2x2 || this.skin === PlayerModuleSkin.Box4x1;
      classes['gloss-effect--small'] = this.skin === PlayerModuleSkin.Box2x2;
      classes['gloss-effect--large'] = this.skin === PlayerModuleSkin.Box4x1;

      return classes;
    },
    moduleStyle() {
      if (!this.playerModule.background) {
        return null;
      }

      return {
        background: this.playerModule.background,
      };
    },
    coverImageClasses() {
      return {
        'cover-image': true,
        'rounded rounded--tl': this.playerModule.skin === PlayerModuleSkin.ListItem,
      };
    },
    isProduct() {
      return this.type === PlayerModuleType.Product;
    },
    showIcon() {
      return this.playerModule.skin !== PlayerModuleSkin.ListItem && this.playerModule.icon;
    },
    imageUrl() {
      if (this.isProduct && this.playerModule.product.image) {
        return this.playerModule.product.image.url;
      }

      if (!this.playerModule.background_image) {
        return null;
      }

      return this.playerModule.background_image.url;
    },
    title() {
      if (
        this.type === PlayerModuleType.Product
        && this.playerModule.product
        && this.playerModule.product.name
      ) {
        return this.playerModule.product.name;
      }

      return this.playerModule.title;
    },
  },
  methods: {
    onClick() {
      // this.$store.commit('reward/increase');

      if (
        this.playerModule.type === PlayerModuleType.Ad
        || this.playerModule.type === PlayerModuleType.Link
        || this.playerModule.type === PlayerModuleType.Static
      ) {
        return;
      }

      this.$store.dispatch('navigation/setCurrentModule', this.playerModule);
    },
  }
};
</script>
