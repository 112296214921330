<template>
  <div class="form">
    <ModuleHeader
      :title="form.title"
      :icon="module.icon"
      :sponsorLogo="module.sponsor_logo"
      :divider="true"
    />

    <div v-if="!isSubscribed" class="form--content">
      <div class="form--title">{{ form.description }}</div>
      <div class="form--fields">
        <div class="form--field" v-if="form.name_enabled">
          <input
            v-model="name"
            :class="{ error: nameError }"
            type="text"
            :placeholder="
              form.name_placeholder ? form.name_placeholder : tr('Name')
            "
          />
          <div v-if="nameError" class="error-message">
            {{ nameErrorMessage }}
          </div>
        </div>

        <div class="form--field">
          <input
            type="email"
            v-model="email"
            :class="{ error: emailError }"
            :placeholder="
              form.email_placeholder ? form.email_placeholder : tr('Email')
            "
          />
          <div v-if="emailError" class="error-message">
            {{ emailErrorMessage }}
          </div>
        </div>

        <div class="form--field" v-if="form.phone_enabled">
          <input
            v-model="phone"
            :class="{ error: phoneError }"
            type="tel"
            :placeholder="
              form.phone_placeholder ? form.phone_placeholder : tr('Phone')
            "
          />
          <div v-if="phoneError" class="error-message">
            {{ phoneErrorMessage }}
          </div>
        </div>
      </div>
      <div class="form--checkboxes">
        <label
          v-for="(checkboxLabel, index) in form.checkbox_labels"
          :class="{ error: checkboxErrorIndex.includes(index) }"
          :key="index"
          class="checkbox"
        >
          <input type="checkbox" v-model="checkboxes[index]" />
          {{ checkboxLabel }}
        </label>
      </div>
      <button class="button subscribe-button gloss-effect" @click="submit()">
        <i class="icon loader white" v-if="isLoading"></i>
        <template v-else>{{ tr('Subscribe') }}</template>
      </button>
    </div>

    <div class="form--content" v-else>
      <div class="subscribed">
        {{ tr('Thank you for subscribing!') }}
        <BackButton />
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import ModuleHeader from '@/components/ModuleHeader.vue';
import { PlayerModule } from 'smucio-stream-entities';
import { isValidEmail } from '@/utils/validators';
import { toast } from 'vue3-toastify';
import axios from 'axios';

export default {
  name: 'FormComponent',
  components: {
    ModuleHeader,
    BackButton,
  },

  data: () => ({
    name: '',
    email: '',
    phone: '',
    checkboxes: [],
    nameError: false,
    emailError: false,
    phoneError: false,
    checkboxErrorIndex: [],

    nameErrorMessage: '',
    emailErrorMessage: '',
    phoneErrorMessage: '',

    isLoading: false,
    isSubscribed: false,
  }),

  props: {
    module: {
      type: PlayerModule,
      required: true,
    },
  },

  created() {
    this.checkboxes = this.module.form.checkbox_labels
      ? this.module.form.checkbox_labels.map(() => false)
      : [];
  },

  watch: {
    name() {
      this.clearErrors();
    },
    email() {
      this.clearErrors();
    },
    phone() {
      this.clearErrors();
    },
    checkboxes: {
      handler: function () {
        this.clearErrors();
      },
      deep: true,
    },
  },

  computed: {
    form() {
      return this.module.form;
    },
  },

  methods: {
    validate() {
      if (this.form.name_enabled && this.name.length === 0) {
        this.nameError = true;
        this.nameErrorMessage = this.tr('Name is required');
      } else {
        this.nameError = false;
      }

      if (this.email.length === 0) {
        this.emailError = true;
        this.emailErrorMessage = this.tr('Email is required');
      } else if (!isValidEmail(this.email)) {
        this.emailError = true;
        this.emailErrorMessage = this.tr('Invalid email');
      } else {
        this.emailError = false;
      }

      if (this.form.phone_enabled && this.phone.length === 0) {
        this.phoneError = true;
        this.phoneErrorMessage = this.tr('Phone is required');
      } else {
        this.phoneError = false;
      }

      this.checkboxErrorIndex = [];
      this.checkboxes.forEach((checkbox, index) => {
        if (!checkbox) {
          this.checkboxErrorIndex.push(index);
        }
      });

      return (
        !this.nameError &&
        !this.emailError &&
        !this.phoneError &&
        this.checkboxErrorIndex.length === 0
      );
    },

    clearErrors() {
      this.nameError = false;
      this.emailError = false;
      this.phoneError = false;
      this.checkboxErrorIndex = [];
    },

    submit() {
      if (this.isLoading) {
        return;
      }
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      const url = process.env.VUE_APP_FORM_API_URL;

      const data = new FormData();
      data.append('id', this.form.id);
      data.append('name', this.name);
      data.append('name_required', this.form.name_enabled ? 1 : 0);
      data.append('email', this.email);
      data.append('phone', this.phone);
      data.append('phone_required', this.form.phone_enabled ? 1 : 0);
      axios
        .post(url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.clearErrors();
          this.name = '';
          this.email = '';
          this.phone = '';
          this.checkboxes = this.checkboxes.map(() => false);
          this.isSubscribed = true;
        })
        .catch((error) => {
          toast.error(this.tr(error.response.data.errors[0] || this.tr('Failed to subscribe')));
        })
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
