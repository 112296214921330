<template>
  <div class="heart-gun-component">
    <div v-for="item in items" :key="'item--' + item.id" :class="item.classes">
      <div class="icon" />
    </div>

    <div class="wrapper">
      <div class="counter">
        {{ counterLabel }}
      </div>
      <div
        class="heart-gun-button pulse-button pulse-button--light pulse-button--round"
        @click="onClick"
      >
        <i class="icon red heart" />
      </div>
    </div>
  </div>
</template>

<script>
import { WsClientEvents } from 'smucio-ws-client';

export default {
  name: 'HeartGun',
  props: {
    runDemo: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    items: [],
    counter: 0,
    demoTimers: [],
  }),
  computed: {
    counterLabel() {
      if (this.counter < 1000) {
        return this.counter;
      }

      let resp = parseFloat((this.counter / 1000).toFixed(1));
      return resp + 'K';
    },
  },
  watch: {
    '$root.wsClient': {
      immediate: true,
      handler(val) {
        if (val !== null) {
          val.addEventListener(WsClientEvents.HEART, () => this.trigger());
          val.addEventListener(WsClientEvents.INFO, (info) =>
            this.updateCounter(info.heartCount)
          );
        }
      },
    },
    runDemo: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => this.initDemoAnimation());
        } else {
          for (let i in this.demoTimers) {
            if (this.demoTimers[i] !== null) {
              clearTimeout(this.demoTimers[i]);
              this.demoTimers[i] = null;
            }
          }
        }
      },
    },
  },
  methods: {
    initDemoAnimation() {
      const observer = new IntersectionObserver((entries) => {
        const isVisible = entries[0].isIntersecting;
        if (isVisible) {
          observer.disconnect();

          const animationCount = Math.floor(Math.random() * 10) + 10;
          const animationDuration = 5000;

          for (let i = 0; i < animationCount; ++i) {
            let delay = Math.random() * animationDuration;
            this.demoTimers[i] = setTimeout(() => {
              this.demoTimers[i] = null;
              this.trigger(true);
            }, delay);
          }
        }
      });
      observer.observe(this.$el);
    },
    onClick() {
      if (this.$root.wsClient !== null) {
        this.$root.wsClient.heart();
      }
      this.trigger();
    },
    trigger(ignoreCounter) {
      if (!ignoreCounter) {
        ++this.counter;
      }

      const itemId = this.generateItemId();
      const randomX = Math.floor(Math.random() * 3 + 1);
      const randomY = Math.floor(Math.random() * 3 + 1);
      const randomXDelay = Math.floor(Math.random() * 3 + 1);
      const randomYDelay = Math.floor(Math.random() * 3 + 1);

      this.items.push({
        id: itemId,
        classes: [
          'item',
          'shake-y-' + randomY,
          'shake-x-' + randomX,
          'shake-x-delay-' + randomXDelay,
          'shake-y-delay-' + randomYDelay,
        ],
      });
      setTimeout(() => {
        this.items = this.items.filter((e) => e.id !== itemId);
      }, 50000);
    },
    generateItemId() {
      return (Math.random() + 1).toString(36).substring(7);
    },
    updateCounter(value) {
      this.counter = value;
    },
  },
};
</script>
