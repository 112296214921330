<template>
  <div :class="pageCssClasses">
    <div
      v-if="pageHasBackgroundImage"
      class="page--bg"
    >
      <span>
        <img :src="this.module.background_image" />
      </span>
    </div>
    <div class="page--content">
      <img v-if="computedSponsorLogo" class="sponsor-logo" :src="computedSponsorLogo" alt="sponsor-logo" />

      <div class="page--topper" :style="{'flex': `0 0 ${contentTopPadding}px`}"></div>

      <img
        v-if="typeof module.icon === 'string' && module.icon.length > 0"
        :src="module.icon"
        class="reward-icon"
      />
      <div class="title" v-html="title" />
      <div
        v-if="module.content && module.content.length > 0"
        class="content"
        v-html="computeContent"
      />

      <div
        v-if="isRedeemPage"
        ref="redeemForm"
        class="redeem-form"
      >
        <input
          v-model="emailAddress"
          type="text"
          :placeholder="tr('You email')"
        >
        <label class="redeem-form--consent">
          <input
            v-model="isUserConsent"
            type="checkbox"
            :placeholder="tr('You email')"
          >
          {{ tr('I consent to the processing of my given data') }}
        </label>

        <button-glossy
          :label="isFormSending ? tr('Redeeming..') : tr('Instant reward')"
          :disabled="isFormSending || !formIsValid"
          @click="getInstantReward"
        />
      </div>

      <div
        v-if="module.ad !== null"
        class="reward--ad-wrapper"
        v-html="customAd"
      />
    </div>
  </div>
</template>

<script>
import {
  Media,
  PlayerModule,
  Page,
  RewardPage,
  RewardPageLayout,
} from 'smucio-stream-entities';
import Ad from '@/components/Ad.vue';
import ButtonGlossy from '@/components/ButtonGlossy.vue'

import { toast } from 'vue3-toastify';
import axios from 'axios';

import { render, h } from 'vue';

export default {
  name: 'RewardPageComponent',
  components: {
    ButtonGlossy
  },
  props: {
    module: {
      type: Object,
      required: true,
      validator: (value) => {
        return (
          value instanceof PlayerModule ||
          value instanceof Page ||
          value instanceof RewardPage
        );
      },
    },
  },
  data() {
    return {
      isFormSending: false,
      emailAddress: '',
      isUserConsent: false,
      bgTopOffset: 0,
      contentTopPadding: 0,
      safeAreaBottomPadding: 50,
    };
  },
  mounted() {
    // this.bgTopOffset = this.$el.offsetTop * -1

    if (this.$refs.redeemForm) {
      this.contentTopPadding = this.$el.offsetHeight - this.$refs.redeemForm.offsetTop - this.$refs.redeemForm.offsetHeight - this.safeAreaBottomPadding
    }

    if (this.isClaimPage) {
      this.$store.dispatch('reward/setIsRedeemed', 2)
    }
  },
  computed: {
    isRedeemPage() {
      return this.module.layout === RewardPageLayout.RedeemPage
    },
    isClaimPage() {
      return this.module.layout === RewardPageLayout.ClaimPage
    },
    page() {
      return this.module.page ? this.module.page : this.module;
    },
    pageCssClasses() {
      const classes = {
        page: true,
        'page--reward': true,
      };

      if (this.module.layout === RewardPageLayout.RedeemPage) {
        classes['layout--redeem'] = true;
      } else if (this.module.layout === RewardPageLayout.ClaimPage) {
        classes['layout--claim'] = true;
      }

      return classes;
    },
    pageHasBackgroundImage() {
      return (
        typeof this.module.background_image === 'string' &&
        this.module.background_image.length > 0
      );
    },
    // pageBGStyles() {
    //   const styles = {};

    //   if (this.pageHasBackgroundImage) {
    //     styles['background'] = 'transparent url(' + this.module.background_image + ') no-repeat top center';
    //     styles['top'] = this.bgTopOffset + 'px';
    //   }

    //   return styles;
    // },
    computeContent() {
      // Take out shorttag for Ad [ad src="" link="" rounded=""]
      const adShortTag =
        /\[ad\s+img="([^"]+)"\s+link="([^"]+)"(\s+rounded="(true|false)")?\]/g;

      // Replace shorttag with Ad component
      let replacedContent = this.page.content.replace(
        adShortTag,
        (match, src, link, _roundedWrapper, rounded) => {
          const playerModule = new PlayerModule({
            ad: {
              media: new Media({ url: src }),
              link,
              rounded: rounded === 'true',
            },
          });
          const adComponent = h(Ad, { class: 'in-page', playerModule });
          const adElement = document.createElement('div');
          render(adComponent, adElement);
          return adElement.innerHTML;
        }
      );

      return replacedContent;
    },
    customAd() {
      if (this.module.ad === null) {
        return ''
      }

      const playerModule = new PlayerModule({
        ad: this.module.ad,
      });
      const adComponent = h(Ad, { class: 'in-page', playerModule });
      const adElement = document.createElement('div');
      render(adComponent, adElement);
      return adElement.innerHTML;
    },
    title() {
      return this.module.title;
    },
    formIsValid() {
      return typeof this.emailAddress == 'string'
        && this.emailAddress.length > 0
        && this.isValidEmail(this.emailAddress)
        && this.isUserConsent === true
    },
    computedSponsorLogo() {
      if (this.module.sponsor_logo === '') {
        return false;
      }
      return this.module.sponsor_logo ? this.module.sponsor_logo : this.$root.defaultSponsorLogo;
    },
  },
  methods: {
    getInstantReward() {
      if (!this.formIsValid && this.isFormSending) {
        return;
      }

      this.isFormSending = true;
      const url = process.env.VUE_APP_REWARD_CONSENT_FORM_API_URL;

      const data = new FormData();
      data.append('key', this.$store.getters['app/key']);
      data.append('email', this.emailAddress);
      axios
        .post(url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.emailAddress = '';

          this.$store.dispatch('reward/setIsRedeemed', 1);
          this.$store.dispatch('navigation/goBack');

          // @TODO - Find a better solution!
          setTimeout(() => {
            const viewRewardEl = document.querySelector('.reward-bar-component .button--glossy')
            if (viewRewardEl) {
              viewRewardEl.click();
            }
          }, 500)
        })
        .catch((error) => {
          if (
            error
            && typeof error.response !== 'undefined'
            && typeof error.response.data !== 'undefined'
            && typeof error.response.data.errors !== 'undefined'
            && Array.isArray(error.response.data.errors)
            && error.response.data.errors.length > 0
          ) {
            toast.error(this.tr(error.response.data.errors[0]));
          } else {
            toast.error(this.tr('Failed to redeem. Please try again later.'));
          }
        })
        .finally(() => this.isFormSending = false);
    },
    isValidEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  }
};
</script>
