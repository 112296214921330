<template>
  <div class="light-cheer">
    <ModuleHeader
      :title="module.title"
      :icon="module.icon"
      :sponsorLogo="module.sponsor_logo"
      divider
    />

    <div class="light-cheer--content">
      <div
        v-for="(team, index) in lightCheer.teams"
        :key="index"
        class="team gloss-effect gloss-effect--large"
        :style="{ '--bg-color': team.color }"
        @click="selectColor(team.color)"
      >
        <img
          v-if="team.logo && team.logo.url"
          :src="team.logo.url"
          class="team--logo"
        />
        <div class="team--text">{{ team.name }}</div>
      </div>
    </div>

    <transition name="fade-fast">
      <div v-show="isModalOpened" class="light-cheer-modal-container">
        <div
          v-show="isModalOpened"
          class="light-cheer--modal"
          :style="{ '--modal-color': currentColor }"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import RewardMixin from '@/mixins/Reward.js';
import ModuleHeader from '@/components/ModuleHeader.vue';

import { PlayerModule } from 'smucio-stream-entities';
import { mapState } from 'vuex';

export default {
  name: 'LightCheer',
  mixins: [RewardMixin],
  components: {
    ModuleHeader,
  },
  props: {
    module: {
      type: PlayerModule,
      required: true,
    },
  },
  data() {
    return {
      selectedColor: null,
      isModalOpened: false,
      currentColor: '#000',
      colorInterval: null,
    };
  },
  computed: {
    lightCheer() {
      return this.module.light_cheer;
    },
    ...mapState({
      isInPseudoModule: (state) => state.navigation.isInPseudoModule,
    }),
  },
  watch: {
    isInPseudoModule() {
      if (!this.isInPseudoModule) {
        this.isModalOpened = false;
        this.$emit('setFullWidth', false);
        this.stopColorInterval();
      }
    },
  },
  methods: {
    selectColor(color) {
      this.stopColorInterval();

      this.selectedColor = color;
      this.currentColor = this.selectedColor;
      this.isModalOpened = true;
      this.$emit('setFullWidth', true);
      this.$store.dispatch('navigation/goToPseudoModule');

      this.$nextTick(() => {
        setTimeout(() => {
          this.colorInterval = setInterval(this.updateColor, 1);
        }, 1000 - (new Date()).getMilliseconds());
      });

      this.creditReward();
    },
    stopColorInterval() {
      if (this.colorInterval !== null) {
        clearInterval(this.colorInterval);
        this.colorInterval = null;
      }
    },
    updateColor() {
      const timeValue = Date.now() % 1000;
      this.currentColor = (timeValue >= 300) ? this.selectedColor : '#000';
    },
  },
  beforeUnmount() {
    this.stopColorInterval();
  }
};
</script>
