<template>
  <div class="product">
    <ModuleHeader
      :title="productName"
      :description="renderedPrice"
      :icon="module.icon"
      :sponsorLogo="module.sponsor_logo"
    />

    <div class="product--content">
      <img v-if="imageUrl" :src="imageUrl" class="image" />
      <div v-if="hasVariations" class="variations">
        <div class="variations--label">{{ tr('Variations') }}</div>
        <v-select
          v-model="selectedVariation"
          :placeholder="tr('Choose a variation')"
          :options="product.variations"
          :reduce="item => item.id"
          :get-option-label="item => buildVariationLabel(item)"
          :searchable="false"
          :clearable="false"
        />
      </div>
      <div v-if="description" class="description" v-html="description" />
    </div>

    <div class="bottom-bar">
      <button class="subscribe-button" @click="() => openStripeForm()">
        {{ buttonLabel }}
      </button>
    </div>

    <transition name="fade-fast" mode="out-in">
      <stripe-form
        v-if="isModalOpened"
        :product="productToStripeForm"
      />
    </transition>
  </div>
</template>

<script>
import ModuleHeader from '@/components/ModuleHeader.vue';
import ProductPrice from '@/components/ProductPrice.vue';
import StripeForm from '@/components/StripeForm.vue';
import { formatPrice } from '@/utils/formatters';

import { toast } from 'vue3-toastify';
import { render, h } from 'vue'
import { PlayerCardProduct, PlayerModule } from 'smucio-stream-entities';

export default {
  name: 'PageComponent',
  components: {
    ModuleHeader,
    StripeForm,
  },
  props: {
    module: {
      type: PlayerModule,
      required: true,
    },
  },
  data: () => ({
    selectedVariation: null,
  }),
  computed: {
    product() {
      return this.module.product;
    },
    productToStripeForm() {
      if (!this.hasVariations) {
        return this.product;
      }

      if (!this.selectedVariation) {
        return null;
      }

      return this.product.variations.find(v => v.id == this.selectedVariation && v.quantity > 0);
    },
    currency() {
      if (this.product.currency) {
        return this.product.currency;
      }

      if (this.hasVariations) {
        const productWithCurrency = this.product.variations.find(product => product.currency);
        if (productWithCurrency) {
          return productWithCurrency.currency;
        }
      }

      return null;
    },
    productName() {
      return this.product.name;
    },
    description() {
      return this.product.description ? this.product.description.replaceAll('\n', '<br>') : null;
    },
    hasVariations() {
      return this.product.variations.length !== 0;
    },
    renderedPrice() {
      const element = document.createElement('div');
      const component = h(ProductPrice, {
        product: new PlayerCardProduct(this.product.toObject()),
        selectedVariation: this.productToStripeForm,
      });
      render(component, element);

      return element.innerHTML;
    },
    imageUrl() {
      return this.product.image ? this.product.image.url : null;
    },
    mustSelectVariation() {
      return this.hasVariations && !this.selectedVariation;
    },
    buttonLabel() {
      if (this.mustSelectVariation) {
        return this.tr('Choose a variation');
      }

      return this.tr('Buy now');
    },
    isModalOpened() {
      return this.$store.getters['navigation/isInPseudoModule'];
    },
  },
  watch: {
    selectedVariation(val) {
      if (val !== null && !this.productToStripeForm) {
        toast.info('The selected variation is out of stock.');
        this.$nextTick(() => this.selectedVariation = null);
      }
    },
  },
  methods: {
    openStripeForm() {
      if (this.hasVariations && !this.selectedVariation) {
        toast.info('Please choose an available variation.');
        return;
      }

      this.$store.dispatch('navigation/goToPseudoModule');
    },
    buildVariationLabel(variation) {
      let label = variation.name + ' - ';
      if (variation.quantity > 0) {
        label += ' ' + formatPrice(variation.sale_price ?? variation.price, this.currency);
      }
      else {
        label += ' ' + this.tr('Out of stock');
      }

      return label;
    }
  },
};
</script>
